import React from 'react'
import BEGREAT from '../images/BEGREAT.png';
import "../css/confirmorder.css";


const Confirmorder = () => {





    return (
        <div class="all-product-order">
              <section class="section2-products">
            <div class="container">
             <div class="row justify-content-center ">
                            <div class="col-md-10 col-lg-7">
                                <div class="confm-order">
                                <div class="head-order-cnfm">
                                    Order Confirmed!
                                </div>
                                <div class="thank-you-order">
                                Thank you for your order! You will receive an email shortly confirming your order.

                                </div>
                                <div class="cnfm-bgreat">
                                <img class="pro-image" src={BEGREAT} ></img>
                                </div>
                                <div class="live-life-order">
                                Live Life in High Definition.
                                </div>
                                <div class="shopping-con">
                                <input class="product-btn-default-confirm" type="submit" value="CONTINUE SHOPPING" ></input>
                                </div>
                                </div>
            
        </div>
        
        </div>
        
        </div>
        </section>
        </div>
    )
}

export default Confirmorder;
