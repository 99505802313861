import React from 'react';
import "../css/payment.css";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import Applpaya from '../images/bigapp.png';
import Paypala from '../images/bigpay.png';



const Payment = () => {
	return (
		<div class="all-product">
			<section class="section2-products">
				<div class="container">
					<div class="prog-bar">
					<Link className="clr-dot" to="/cart"> Your Cart </Link> <i class="arrow right"></i><Link  className="clr-dot" to="/Shipping"> Contact and Shipping </Link><i class="arrow right"></i><Link  className="clr-dot" to="/payment"> Payment </Link> <i class="arrow right"></i><Link  className="clr-dot" to="/Summary"> Summary </Link>
					</div>
					<div class=" cont-act-cart col-sm-10">
						<div class="row">
							<div class="m-acc">
								<h4 class="pro-duct-your-cart" >Payment</h4>
							</div>

							<div class="ship-sign-pay">

								<div style={{ paddingBottom: "10px" }}>
									<a class="app-image" href="http://example/">  <img class="appbig-image" src={Applpaya} ></img></a>
								</div>
								<div style={{ paddingBottom: "10px" }}>
									<a class="app-image" href="http://example/"> <img class="appbigs-image" src={Paypala} ></img></a>
								</div>
								<div style={{ paddingBottom: "10px" }}>
									<a class="product-btn-story-cart-ship-pay" href="http://example/">USE A GIFT CARD</a>
								</div>
							</div>


						</div>
					</div>
					<div class="row">
						<div class="col-md-6 col-sm-12">
							<div class="review-leave-ship-pay">
								Payment Information
							</div>
							<div id="product-11" class="single2-product">
								<div class="detail-ship">
									<div class="col">
										<input type="text" class="form-control-ship " placeholder="Credit Card Number" id="fname" name="fname" />
									</div>
									<br />

									<div class="col-cvv">
										<input type="text" class="form-control-ship-exp " placeholder="Exp.(MM/YYYY)" id="fname" name="fname" />
										<input type="text" class="form-control-ship-exp " placeholder="CVV" id="fname" name="fname" />
									</div>
									<br />

									<div class="col">
										<input type="text" class="form-control-ship " placeholder="Phone Number" id="fname" name="fname" />
									</div>
									<br />
									<div class="fade-cl"> For shipping Updates</div>
								</div>
							</div>
							<div class="review-leave-ship-pay">
								Billing Address
							</div>
							<input type="checkbox" />
                            Same as Shipping Address
						</div>


						<div class="col-md-6 col-sm-12">


							<div class="review-leave-ship-pay-ord">
								In Your Cart
							</div>
							
							<div class="order-detail-1">
							<div class="cart-sub-hr-line">
                        <hr ></hr>
						</div>
								<div class="row">
									<div class="col-3">
										<div class="part-cart-pay">
										</div>
									</div>
									<div class="col-5">
										<div class=" cart-two ">
											<div class="part-1-cart-ship-pay">
												<h3 class="product-great"><b>Product</b></h3>
												<div class="product-kgreat">
											Flavor<br/>
											 Size<br/>
                                             One Time Purchase<br/>
											</div>
											</div>
											<div class="part-2-cart-ship-pay">
												<h3 class="product-price-pay">$00.00</h3>
											</div>
										</div>
									</div>
									<div class="col-4">
										<div class=" cart-two-ship-pay">
											<div class="arrow-cart-one-ship-pay">
												<button class="sort-arrow-cart-ship"> <i class="arrow left"></i>&nbsp; 1 &nbsp;<i class="arrow right"></i></button>
											</div>
											<div class="opt-three-pay">
												Edit<br />
                               Remove<br />
                               Save for later<br />
											</div>
										</div>
									</div>

								</div>
								<div class="cart-sub-hr-line">
                        <hr ></hr>
						</div>
							</div>
							<div class="order-detail">
							<div class="row">
								<div class="sumup col-sm-10 col-md-12 ">
								<div class="review-leave-ship-pay-ord">
									Summary
							
									</div>
									</div>
									</div>
								<div class="col-cnfm">
									<input type="text" class="form-control-ship-exp-cnfm " placeholder="Add a promo code" id="fname" name="fname" />
									<input class="product-btn-default-cnfm" type="submit" value="Apply" ></input>
								</div>
								<div class="sub-total-cnfm">
								<div class="cart-sub-hr-line">
                        <hr ></hr>
						</div>
											<div class="order-total">
												<br/>
												Subtotal
												<br/>
												Discount
												<br/>
												Shipping
												<br/>
												Tax
												</div>
												<div class="amount-totl">
						<br/>
						$00.00
						<br/>
						$00.00
						<br/>
						$00.00
						<br/>
						$00.00
						<br/>
					</div>
												<div class="cart-sub-hr-line">
                        <hr ></hr>
						</div>
							    	<div class="sub-tt">
										Total
									</div>
									<div class="sub-tot">
										$00.00
									</div>
									<div class="sales-amt">
									1% of sales supports Veteran causes.
									</div>
										
										<div class="continue-btn">
										<input class="product-btn-default-cnfm-CON" type="submit" value="CONTINUE" ></input>
										</div>
								
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

		</div>
	)
}

export default Payment;