import "../css/Signinone.css";
import { Link } from "react-router-dom";
import LOGOORANGE from "../images/Tecton side by side white logo.png";
import LOGOSIDE from "../images/Tecton side by side white logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircle} from "@fortawesome/free-solid-svg-icons"; 
import BEGREAT  from '../images/BEGREAT.png';
import {faBars,faCartShopping} from "@fortawesome/free-solid-svg-icons";
import Logo from "../images/Tecton side by side white logo.png";
import {
  Navbar,
  Container,
  NavDropdown,
  Offcanvas,
  Nav,
} from "react-bootstrap";
import {useEffect,useState} from "react"

function Signinone(){


    return(<>
    <div className="header" style={{backgroundColor:"black"}}>
 <div className="header_logo">
      <Link to="/" onClick={()=>('.navbar-collapse').collapse('hide')}> <img src={Logo} alt="Logo da Marca"></img></Link>
      </div>
      <div className="header_center">
      <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopLeftRadius:"22px",borderBottomLeftRadius:"22px"}}>BE GREAT</p></Link>
      <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" >AMBASSADOR</p></Link>
      <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem" >SCIENCE</p></Link>
      <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem" >CONTACT</p></Link>
      <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" >ACCOUNT</p></Link>
      <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem"  ><NavDropdown  title="SHOP">
              <NavDropdown.Item>SHOP ALL</NavDropdown.Item>
              <NavDropdown.Item>TECTON</NavDropdown.Item>
              <NavDropdown.Item>MERCHANDISE</NavDropdown.Item>
            </NavDropdown></p></Link>
            <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopRightRadius:"22px",borderBottomRightRadius:"22px"}}><FontAwesomeIcon icon={faCartShopping} style={{fontWeight:"bolder"}}/></p></Link>
      </div>



<div className="revealGhostItems">
  <Navbar className="cont" expand={false} id="colorwhite">
    <Container fluid>
    <Link to="/preorder"><span><FontAwesomeIcon icon={faCartShopping} style={{color:"white",fontWeight:"bolder",fontSize:"30px"}}/></span></Link>
      <Navbar.Toggle href="#" className="menu">
      <span><FontAwesomeIcon icon={faBars} style={{color:"white",fontWeight:"bolder"}}/></span>
      </Navbar.Toggle>
      <Navbar.Offcanvas
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav style={{textAlign:"right"}}>
            <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">BE GREAT</Link>
            <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">AMBASSADOR</Link>
            <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">SCIENCE</Link>
            <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">CONTACT</Link>
            <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">ACCOUNT</Link>
            <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><NavDropdown title="SHOP">
              <NavDropdown.Item>SHOP ALL</NavDropdown.Item>
              <NavDropdown.Item>TECTON</NavDropdown.Item>
              <NavDropdown.Item>MERCHANDISE</NavDropdown.Item>
            </NavDropdown></Link>
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Container>
  </Navbar>
</div>
</div>
        <div className="Signinone container">
           <form>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label" style={{color:"orange"}}><h3><b>Sign up</b></h3></label>
  </div>
  <div class="mb-2">
    <label for="exampleInputPassword1" class="form-label"><b>Join the (extra)ordinary club.</b></label>
  </div>

  <div class="mb-4  ">
    <label for="exampleInputPassword1" class="form-label">Get early access to new products, rewards, health tips, and more!</label>
  </div>

  <div class="mb-2">
    <label for="exampleInputPassword1" class="form-label"><b>Select all that apply: I want to</b></label>
  </div>
  <div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Buy products for myself</label>
  </div>
  <div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Apply to be an ambassador</label>
  </div>
  <div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Buy in bulk for resale</label>
  </div>
  <button type="submit" class="btn px-5 py-2" style={{backgroundColor:"orange",color:"white"}}>CONTINUE</button>
  <div class="mt-3">
    <label for="exampleInputPassword1" class="form-label" >Already have an account? <span style={{color:"orange"}}>Login.</span></label>
  </div>
</form>
        </div>
        </>
    )
}

export default Signinone;