import React from 'react';
import "../css/quikadd.css";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactStars from "react-rating-stars-component";
import { faTimes } from '@fortawesome/react-fontawesome';
import {  useState } from 'react';



const QuickAdd =({close})=> {
	
		
	const ratingChanged = (newRating) => {
		console.log(newRating);
    };
	document.getElementById("star-icon")

	const[cane1,setcane1]=useState("white");
	const[cane2,setcane2]=useState("white");
	const[cane3,setcane3]=useState("white");
	
	
	
	const handlecolour1=()=>{
	setcane1("orange");
	setcane2("white");
	setcane3("white")
	}
	
	const handlecolour2=()=>{
		setcane2("orange");
	setcane1("white");
	setcane3("white")
	}
	
	const handlecolour3=()=>{
		setcane3("orange");
	setcane2("white");
	setcane1("white")
	}


	return (
		<div className="popup-box-quick">
		{/* <aside className='login' > */}
		<div className="box-quick">
		<div class="all-product">
			
			<div class="container">
			<div class=" cont-act-for col-sm-8">
                        <div class="row">
                            <div class="m-acc">
                                <h3 class="pro-duct-quick" >Quick Add</h3>
								
							<span className="close-icon" onClick={() => close()} > x</span>
						
                            </div>
							
							</div>
							
							</div>
				<div class="row">

				
					<div class="col-sm-6">
						<div id="product-11" class="single2-product">
							<div class="part-quick">

							</div>

						</div>
					</div>



					<div class="col-sm-6">
						<div id="product-2" class="single22-product">

							<div class="pro-part1">
								<div class="pro-duct-only-quick" >
									<h3 >Magma</h3>   </div>
								<div class="product-k">Ketone Hydration</div>

								<div style={{ paddingBottom: "40px" }}>
									<div class="star-icon">

										<ReactStars
											count={5}
											onChange={ratingChanged}
											size={24}
											isHalf={true}
											emptyIcon={<i className="far fa-star"></i>}
											halfIcon={<i className="fa fa-star-half-alt"></i>}
											fullIcon={<i className="fa fa-star"></i>}
											activeColor="#ffd700"
										/>


									</div>
								</div>
								<div class="product-old-p">Flavour</div>

								<FontAwesomeIcon icon={faCircle} style={{ color: "#8080802e", size: "30px", paddingRight: "5px" }} /><FontAwesomeIcon icon={faCircle} style={{ color: "#8080802e", size: "2px" }} />
								<div class="glow-m">Magma glows from its passion for life with <br />refreshing fruity berry notes. </div>
								<div class="inline-buttons">
									<p>
									<span class="one-third  button" style={{cursor:"pointer",backgroundColor:`${cane1}`}} onClick={handlecolour1}>Single cane</span>
										<span class="one-third button" style={{cursor:"pointer",backgroundColor:`${cane2}`}} onClick={handlecolour2}>4-pack</span>
										<span class="one-third button" style={{cursor:"pointer",backgroundColor:`${cane3}`}} onClick={handlecolour3}>12-pack</span>
									</p>
								</div>
								<h3 class="product-price"><b>$00.00</b></h3>
								<div class=" sub-scribe">
									<input class="product-btn-single" type="submit" value="Subscribe and save 5%" ></input>
								</div>
								<br />
								<br />
								<div class="arrow-cart">
									<button class="sort-arrow"> <i class="arrow left"></i>&nbsp; 1 &nbsp;<i class="arrow right"></i></button>
									<input class="product-btn-default-single-quick" type="submit" value="ADD TO CART" ></input>
								</div>
								<div class="ask-add-quick" >
									<input class="product-btn-subs-add-quick" type="submit" value="ADD TO FAVORITES" ></input>
								</div>
								<br />
                                <br />
                                <br />
                                <br />
                                <div class=" sub-scribe-quick">
									<input class="product-btn-single" type="submit" value="More information" ></input>
								</div>
								
							</div>
						</div> 
					  </div>
                    </div> 
					</div>
                   

		</div>
		</div>
		</div>
		
	)
}

export default QuickAdd;