import React from 'react'
 import'../css/tab3.css'

 import upper from '../images/milatryHEADER.jpg';
 import lower from '../images/milatryFOOTER.jpg';
 import { Link } from 'react-router-dom';
 import BEGREAT  from '../images/BEGREAT.png';



function Tab3() {


    return (
        <div class="cone">
         <div> 
           <img class="ico-3" src={upper} style={{width:"100%"}} >
           </img>
           </div>
            <section class="read-doc">
                    <div class="divide" style={{height:"auto"}}>
<div id="http-one" class="row">
  <div class="col-sm-4 divide-one">

    <div id="dtl3">
    <b style={{fontSize:"117%"}}>Udaiyan Jatar</b><br/>
    <i>Co-Founder & President</i><br/>
    Udaiyan is a former Coca-Cola Global VP who has launched many brands worldwide. He’s also the creator of the 7 Disciplines of Transcendent Brands, <a href="https://youtu.be/xS2B1C_xfSg"style={{color:"black"}}> a model</a> for developing brands that transform lives and markets. A model for doing well by doing good.
    </div>
 
  <br/>
<div id="dtl1">
<div class="text-center">
<Link to="/preorder"> 
<button class="divide-one-button">Pre-Order Now</button><br/>
</Link>


<Link to="/ambassador">
  <button class="divide-two-button">Become an Ambassador</button>
  </Link>
 

 
 </div> </div>
 
  </div>
  
  <div class="col-sm-8 divide-two">
 
 <div id="Heads">
   <div class="tr-ps">
  <h1 style={{ fontSize: "35px" ,color:"orange", fontWeight:"bold", marginBottom: "9px",paddingTop:"0px"}}>True Purpose</h1>
  </div>
 </div>
 <div>
  <p  class="mob-h" style={{  fontWeight:"bold" ,color:"orange", marginBottom: "9px",paddingTop:"9px"}}> Ordinary People</p> </div>
  We have come to realize that it is always “ordinary” people who at some point do the most extraordinary things. It is often a series of little moments or interactions that inspire and empower ordinary people to achieve great things.  
<br/>
<br/>
Mike Chesne, my co-founder, was born and raised in rural Louisiana and grew up on various military bases where his father, 
a veteran, was stationed. Mike is also a veteran - a former Special Forces Combat Medic who has fought and participated in some of 
the most storied battles of the 21st century. While his bravery is beyond doubt (he is the recipient of two purple hearts), it is
 unlikely anyone would have expected him, an enlisted soldier, to be the developer of, what I believe, is a world changing nutrient. 
 The nutrient that Tecton™ is bringing to market. 
<br/>
<br/>
I, too, was born and raised on military bases. Mostly in rural India. My dad, a fighter pilot and a war hero, succumbed at 
the age of 59, to what would have been diagnosed, today, as PTSD. I went to 13 different schools and my grades were a mess. 
There was not a single person I know, friend or foe, who thought I’d amount to much when I was 18. But, with a fair amount of luck and 
a few great mentors, I was blessed with a global career with Coca-Cola within the next 10 years.
 It is there that I was fortunate to see “ordinary” people do extraordinary things. 
<br/>

  <p style={{ fontSize: "160%",color:"orange", marginBottom: "-12px",paddingTop:"9px" }}><b>Business: A Force For Good</b></p>
  <br/>
  I was accosted by an activist while I was running a global joint venture between Coca-Cola and Nestle. 
  This opened my eyes to the power of business to shape the world. After playing a part in Coke’s shift towards healthier beverages,
   and launching a 100% Fairtrade and Carbon Neutral brand, and inspiring innovations like Coca-Cola Freestyle, I resigned from the 
   company. I did not want to launch another beverage.
   Sugary or caffeine filled drinks full of artificial ingredients are not for me or my children. 
<br/>
<br/>
Mike has been “blown up” more than once in Iraq. He has seen people, soldiers and civilians lose life and limb.
 He dedicated his post-military career to helping set up healthcare infrastructure in the war torn countries he fought in.
 And while he was doing research for this, he came across a nutrient technology that DARPA, the Pentagon’s Defense Advanced
 Research Projects Agency (who he had worked for in the past) had invested millions of dollars in developing with the National 
 Institute of Health and others.
 Exogenous ketones. He began to explore ways to refine this nutrient technology in 2014. 
<br/>

<p style={{ fontSize:"160%",color:"orange", marginBottom: "9px",paddingTop:"9px"}}><b>Our True Purpose</b></p>
I met Mike in 2015. Even though we come from, literally and figuratively, opposite ends of the world, we immediately
 built a bond based on a shared purpose:  

 
<br/>
<br/>
<b><i>Leverage the power of business to make a difference to the health and wellbeing of people. And, especially,
 take care of veterans - people who willingly risk their health and wellbeing for others.  </i></b>
<br/>
<br/>
<p style={{lineHeight:"0px"}}>People like Mike and my dad.</p>
<br/>

Thanks to my years at Coke and P&G, I am lucky to have an amazing network of food scientists and operations people who
 want to change the world. Mike and I joined forces and have spent the last 7 years developing an amazing team and refining our
  product. We are grateful to people like Dr. Kevin Foley, M.D. the former Chief of Neurosurgery at Tripler Army Medical Center, 
  and Walter Reed, who helped us fully understand the power of our nutrient technology. He is also our primary angel investor, 
  and a veteran (he was a Lieutenant Colonel in the US Army).  

I am happy to have been forced to change my mind. Launching another beverage, something I swore I would never do again, is now something 
I can be proud of.  
   <br/>
   
 <p style={{ fontSize:"160%" ,color:"orange", marginBottom: "9px",paddingTop:"9px"}}> <b>Support Disabled Veterans</b></p> 
 The founders, and the founding investors of the company, have pledged 1% of our revenues to nonprofits that help disabled
  veterans to put their lives back together. We are also dedicated to hiring veterans, or the spouses or children of veterans, 
  to be our salesforce. Our goal is to create not just jobs, but training and entrepreneurial opportunities that veterans can
   pursue without putting any ceiling to the extraordinary outcomes they can achieve.  
<br/>

  <p style={{ fontSize:"160%",color:"orange", marginBottom: "-12px",paddingTop:"9px"}}><b>Tectonic Plates Of The Body</b></p>
  <br/>
  The surface of the Earth, its mountains, valleys and oceans are shaped by the movement of its tectonic plates. The power of
   Tecton™ is that it helps the mitochondria in our cells metabolize energy in a far more efficient way. And when we are more alert,
    have more focus or endurance, we can make the most of every day little moments. One of these moments might potentially change our
     lives. Or shift the world. That’s why we are named Tecton™. 
<br/>

  <p style={{ fontSize: "140%" ,color:"orange",paddingTop:"9px"}}> <b>Come join our movement.</b></p>
   <p style={{ fontSize:"140%",color:"orange" , marginTop: "-12px"}}> <b>Discover the extraordinary in yourself and other.</b></p>



   <div class="ico-2" >
   <div class="be">

<img class="ft-image" src={BEGREAT} ></img>

</div>
                   </div>
                    
   </div>
 
  </div>
  <div id="http-two" class="row">
  <div class="col-sm-8 divide-two">

<div id="dtl2">
<div class="text-center">
<Link to="/preorder"> 
<button class="divide-one-button" >Pre-Order Now</button><br/></Link>
<Link to="/ambassador">
 <button class="divide-two-button" >Become an Ambassador</button><br/><br/></Link>
</div>
 </div>
 </div>
 <div class="col-sm-8 divide-three">
 <div id="dtl4">
   <div class="Asidee">
    <b style={{fontSize:"117%"}}>Udaiyan Jatar</b><br/>
    <i>Co-Founder & President</i><br/>
    Udaiyan is a former Coca-Cola Global VP who has launched many brands worldwide. He’s also the creator of the 7 Disciplines of Transcendent Brands, <a href="https://youtu.be/xS2B1C_xfSg"style={{color:"black"}}> a model</a> for developing brands that transform lives and markets. A model for doing well by doing good.
    </div>
    </div>
    </div>


 </div>
 

  </div>
  
    <img class="ico-4" src={lower}  ></img>

    
</section>
            
        </div>
    )
}

export default Tab3;
