import React from 'react';
import "../css/cart.css";
import { useEffect } from 'react';
import BEGREAT from '../images/BEGREAT.png';
import { Link } from 'react-router-dom';
import LoginShop from '../components/loginshop'
import { useState } from 'react';


const Cart = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleLogin = () => {
        setIsOpen(!isOpen);
    }

    return (


        <div class="all-product">


            <section class="section2-products">

                <div class="container">
                    <div class="prog-bar">
                        <Link className="clr-dot" to="/cart"> Your Cart </Link> <i class="arrow right"></i><Link className="clr-dot" to="/Shipping"> Contact and Shipping </Link><i class="arrow right"></i><Link className="clr-dot" to="/payment"> Payment </Link> <i class="arrow right"></i><Link className="clr-dot" to="/Summary"> Summary </Link>
                    </div>
                    <div class=" cont-act-cart col-sm-10">
                        <div class="row">
                            <div class="m-acc">
                                <h4 class="pro-duct-your-cart" >Your Cart</h4>

                            </div>
                            <div class="sort-by-cart" >
                                Sign in for express checkout!    <input class="product-btn-story-cart"
                                    type="button"
                                    value="SIGN IN"
                                    onClick={toggleLogin}
                                />
                                {isOpen && <LoginShop
                                    login={<> </>}
                                    handleClose={toggleLogin}
                                />}
                            </div>
                        </div>
                    </div>
                    <div class="cart-hr-line">
                        <hr ></hr>
                    </div>
                    <div class="row">
                    <div class="col-md-6 col-sm-12">
                             <div class="order-detail-11">

                            <div class="row">
                                <div class="col-4">
                                    <div class="part-cart-pay">
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class=" cart-two ">
                                        <div class="part-1-cart-ship-pay">
                                            <h3 class="product-great"><b>Product</b></h3>
                                            <div class="product-kgreat">
                                                Flavor<br />
                                                Size<br />
                                            One Time Purchase<br />
                                            </div>
                                        </div>
                                        <div class="part-2-cart-ship-pay">
                                            <h3 class="product-price-pay">$00.00</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class=" cart-two-ship-pay">
                                        <div class="arrow-cart-one-ship-pay">
                                            <button class="sort-arrow-cart-ship"> <i class="arrow left"></i>&nbsp; 1 &nbsp;<i class="arrow right"></i></button>
                                        </div>
                                        <div class="opt-three-pay">
                                            Edit<br />
   Remove<br />
   Save for later<br />
                                        </div>
                                    </div>
                                </div>
                                <div class=" sub-shop-cart-emp"  >
                                <input class="product-btn-single-carts" type="submit" value="Empty Cart" ></input>
                            </div>
                            </div>



                        
                        </div>
                        </div>

                        <div class="col-md-6 col-sm-12">

                            <div id="product-21" class="single22-product">
                                <div class="cart-hr-line2">
                                    <hr ></hr>
                                </div>

                                <div class="pro-part-cartt">
                                    <div class="pro-duct-cart-only" >
                                        <h4 >Subtotal</h4>   </div>
                                    <div class="product-sub-total">Shipping and Taxes added at checkout</div>
                                    <div class="check-out-btn">
                                        <input class="product-btn-default-cart" type="submit" value="PROCCED TO CHECKOUT" ></input>
                                    </div>
                                    <div class=" sub-shop"  >
                                        <input class="product-btn-single" type="submit" value="continue shopping" ></input>
                                    </div>
                                    <br />
                                    <br />
                                    {/* <div class="arrow-cart">
									<button class="sort-arrow"> <i class="arrow left"></i>&nbsp; 1 &nbsp;<i class="arrow right"></i></button>
									
								</div> */}


                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                <div class="review-all-leave-cart">
                    <div class="review-leave-cart">
                        More ways to <img class="pro-image" src={BEGREAT} ></img>
                    </div>



                  
                    <div class="down-product">
                        <div class="container">
                        <div class="more-way">
                            <div class="row">

                                <div class=" col-3">
                                    <div class="part-car">

                                    </div>
                                    <div class="part-2">
                                        <h3 class="product-great"><b>Product</b></h3>
                                        <h3 class="product-bgreat">details</h3>
                                        {/* <h4 class="product-price">$00.00</h4><br/> */}
                                        {/* <input class="product-btn-default" type="submit" value="QUICK ADD" ></input> */}
                                    </div>
                                </div>
                                <div class=" col-3">
                                    <div class="part-car">

                                    </div>
                                    <div class="part-2">
                                        <h3 class="product-great"><b>Product</b></h3>
                                        <h3 class="product-bgreat">details</h3>

                                    </div>
                                </div>
                                <div class=" col-3">
                                    <div class="part-car">

                                    </div>
                                    <div class="part-2">
                                        <h3 class="product-great"><b>Product</b></h3>
                                        <h3 class="product-bgreat">details</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="help-diff-cart">
                    <div class="row justify-content-center text-center">
                        <div class="col-md-8 col-lg-6">
                            <div class="header-cart">

                                <h2><b>Help Us Make a Difference</b></h2>
                            </div>
                        </div>

                        <div class="ourt-story">
                            <p>Are you an Ambassador to living your life to the fullest? Being healthy, beiing present, and <br />showing up in the wolrd at your very best? <br />
                                <br />
                                Then connect with founder of Tecton and learn more about how we can work <br />together.</p>

                        </div>
                    </div>
                    <div className="btn-our-cart" style={{ textAlign: "center" }}>
                        <input class="product-btn-help" type="submit" value="BECOME AN AMBASSADOR" ></input>
                    </div>
                </div>






            </section>

        </div>
    )
}

export default Cart;



