import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Landing from './Landing';
import Science from './Science';
import Coming from './Coming';
import Preorder from './Preorder';
import Order from './Ambassdor';
import Career from './career';
import Signup from './Signup';
import Signinone from './Signinone';
import Signintwo from './Signintwo';
import Signinthree from './Signinthree';
import Login from './Loginmy';
import Fyr from './Fyr';
import Reset from './Reset';
import ActivationEmail from './ActivationEmail';
// import ComingSoon from "./comingSoon"
// import Read from './grid';
import Tab3 from '../components/Tab3';
import Tab1 from '../components/Tab1';
import Tab2 from '../components/Tab2';
import Tab4 from '../components/Tab4';
import Tab5 from '../components/Tab5';
import LoginShop from './loginshop'
import ProductDesc from './productdesc'


import Products from './products';
import ResetPass from "./ResetPassword";
import { useSelector } from "react-redux";
import Account from './account'

import Subscription from './subscription'

import Billing from './billing'

import Orders  from './order'

import Rewards  from './reward'

import Refer  from './refer'

// import Test  from './test'

import Ambassador2  from './ambassador2'

import Ambassador1 from './ambassador1'

import Allproduct from './allproduct'

import Cart from './cart'

import QuickAdd from './quikadd'

// import Signin from './signin'

import Shipping from './shipping'

import Payment from './payment'

import YourOrder from './yourorder'

import Confirmorder from './confirmorder'

import AddToCart from './addtocart'


// import ComingSoon from './comingSoon';


function Body (){

    const auth = useSelector((state) => state.auth);
    const { isLogged, isAdmin } = auth;

        return (

            <div   className='content'>

            

                <Routes>

                    <Route path='/' element={<Landing />}></Route>

                    {/* <Route path='/comingsoon' element={<ComingSoon />}></Route> */}

                    <Route path='/science' exact element={<Science/>}></Route>

                    <Route path='/careers' element={<Career />}></Route>

                    {/* <Route path='/science/preorder' exact element={<Preorder/>}></Route> */}

                    {/* <Route path='/science/ambassdor' exact element={<Order/>}></Route> */}

                    {/* <Route path='/science/be-great' exact element={<Tab1/>}></Route> */}

                    <Route path='/coming' exact element={<Coming/>}></Route>

                    <Route path='/preorder' exact element={<Preorder/>}></Route>
                     
                    <Route path='/ambassador' exact element={<Order/>}></Route>

                    <Route path='/be-great' exact element={<Tab1/>}></Route>

                    {/* <Route path='/be-great/preorder' exact element={<Preorder/>}></Route> */}

                    {/* <Route path='/be-great/ambassdor' exact element={<Order/>}></Route> */}

                    <Route path='/true-purpose' exact element={<Tab3/>}></Route>

                    {/* <Route path='/true-purpose/preorder' exact element={<Preorder/>}></Route> */}

                    {/* <Route path='/true-purpose/ambassdor' exact element={<Order/>}></Route> */}

                    <Route path='/keto-diets' exact element={<Tab2/>}></Route>

                    {/* <Route path='/keto-diets/preorder' exact element={<Preorder/>}></Route> */}

                    {/* <Route path='/keto-diets/ambassdor' exact element={<Order/>}></Route> */}

                    <Route path='/exogenous-ketones' exact element={<Tab4/>}></Route>

                    {/* <Route path='/fasting/preorder' exact element={<Preorder/>}></Route> */}
 
                    {/* <Route path='/fasting/ambassdor' exact element={<Order/>}></Route> */}

                    <Route path='/fasting' exact element={<Tab5/>}></Route>

                    {/* <Route path='/fasting/keto-diets' exact element={<Tab2/>}></Route> */}

                    {/* <Route path='/exogenous-ketones/preorder' exact element={<Preorder/>}></Route> */}

                    {/* <Route path='/exogenous-ketones/ambassdor' exact element={<Order/>}></Route> */}

                    {/* <Route path='/ambassdor/true-purpose' exact element={<Tab3/>}></Route> */}
{/* 
                    <Route path='/ambassdor/science' exact element={<Science/>}></Route> 

                    <Route path='/ambassdor/contact' exact element={<Preorder/>}></Route>

                    <Route path='/ambassdor/account' exact element={<Preorder/>}></Route>

                    <Route path='/ambassdor/shop' exact element={<Preorder/>}></Route> 
                    
                    <Route path='/preorder/true-purpose' exact element={<Tab3/>}></Route>

                    <Route path='/preorder/science' exact element={<Science/>}></Route> 

                    <Route path='/preorder/contact' exact element={<Preorder/>}></Route>

                    <Route path='/preorder/account' exact element={<Preorder/>}></Route>

                    <Route path='/preorder/shop' exact element={<Preorder/>}></Route> 

                    <Route path='/preorder/ambassdor' exact element={<Order/>}></Route> */}

                    <Route path='/signinone' element={<Signinone />}></Route>

                    <Route path='/signintwo' element={isLogged ?  <div><h1>page not found</h1></div> : <Signintwo/>}></Route> 

                    <Route path='/signinthree' element={<Signinthree/>}></Route> 

                    <Route path='/login' element={isLogged ?  <div><h1>page not found</h1></div> : <Login/>}></Route>

                    <Route path='/signup' element={<Signup />}></Route>

                    <Route path='/fyr' element={isLogged ?  <div><h1>page not found</h1></div> : <Fyr/>}></Route>

                    {/* <Route path='/reset' element={<Reset />}></Route> */}
                    
                    <Route path='/product' element={<Products/>}></Route>

                    <Route path="/user/activate/:activation_token" element={<ActivationEmail/>}/>

                    <Route
          path="/user/reset/:token"
          element={isLogged ? <div><h1>page not found</h1></div> : <Reset/>}
          exact
        />

<Route path='/Log' exact element={<LoginShop/>}></Route>

<Route path='/productD' exact element={<ProductDesc/>}></Route>

<Route path='/account' exact element={<Account/>}></Route>

                    <Route path='/subscription' exact element={<Subscription/>}></Route>

                    <Route path='/billing' exact element={<Billing/>}></Route>

                    <Route path='/order' exact element={<Orders/>}></Route>

                     <Route path='/reward' exact element={<Rewards/>}></Route>

                      <Route path='/refer' exact element={<Refer/>}></Route>

                       {/* <Route path='/test' exact element={<Test/>}></Route> */}

                       <Route path='/ambassador2' exact element={<Ambassador2/>}></Route>

                       <Route path='/ambassador1' exact element={<Ambassador1/>}></Route>

                       <Route path='/Allproduct' exact element={<Allproduct/>}></Route> 


<Route path='/cart' exact element={<Cart/>}></Route>

<Route path='/Quickadd' exact element={<QuickAdd/>}></Route>

{/* <Route path='/SignIn' exact element={<Signin/>}></Route> */}

<Route path='/Shipping' exact element={<Shipping/>}></Route>

<Route path='/Payment' exact element={<Payment/>}></Route>

<Route path='/Summary' exact element={<YourOrder/>}></Route>

<Route path='/confirmorder' exact element={<Confirmorder/>}></Route>

<Route path='/Addtocart' exact element={<AddToCart/>}></Route>


                </Routes>
                
            </div>

        )

    };





export default Body;