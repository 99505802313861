import React from 'react'
import '../css/ambassador1.css'
import { Link } from 'react-router-dom';
// import { Avatar } from 'antd';
import LOGOORANGE from "../images/TECTON beta site.png";
import LOGOSIDE from "../images/TECTON beta site side.png";

function Ambassador1() {
  return (
    <div class="acc">

      <div class="Act"></div>
      <div id="dep2">
        <div class="row">
          <div class=" navl col-sm-1">
            <div class="contact-info">
              <div class="outl">
                <nav
                  id="nav-menu"
                  class="navbar navbar-expand-lg navbar-light fixed-top bgset "
                  style={{ backgroundColor: "black" }}
                >
                  <div class="container-fluid">
                    <a class="navbar-brand" href="#">
                      <Link to="/" onClick={() => ('.navbar-collapse').collapse('hide')}><div onClick={() => (window).scrollTo(0, 0)} class="hide-nav-pc"> <img class="first-image" src={LOGOORANGE} ></img></div>
                        <div onClick={() => (window).scrollTo(0, 0)} class="hide-nav-mobile"> <img class="first-image1" src={LOGOSIDE} ></img></div>
                        {/* <div onClick={()=>(window).scrollTo(0,0)}><img class="second-image" src={TECHW}></img></div> */}
                      </Link>
                    </a>
                    <button
                      class="navbar-toggler navbar-left"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarNavAltMarkup"
                      aria-controls="navbarNavAltMarkup"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                      <div class="navbar-nav ms-auto bgcolor">
                        <div class="navbar-nav">
                          <Link to="/true-purpose"
                            onClick={() => ".navbar-collapse".collapse("hide")}
                            class="nav-link"
                            data-toggle="collapse"
                            data-bs-target=".navbar-collapse"
                          >
                            <b style={{ paddingLeft: "20px" }}>BE GREAT</b>
                          </Link>
                          <Link
                            to="/ambassador1"
                            onClick={() => ".navbar-collapse".collapse("hide")}
                            class="nav-link"
                            data-toggle="collapse"
                            data-bs-target=".navbar-collapse"
                          >
                            <b class="amb-b">AMBASSADOR</b>
                          </Link>

                          <Link
                            onClick={() => ".navbar-collapse".collapse("hide")}
                            to="/science"
                            class="nav-link"
                            href="#"
                            data-toggle="collapse"
                            data-bs-target=".navbar-collapse"
                          >
                            <b>SCIENCE</b>
                          </Link>
                          <Link
                            to="/preorder"
                            onClick={() => ".navbar-collapse".collapse("hide")}
                            class="nav-link"
                            data-toggle="collapse"
                            data-bs-target=".navbar-collapse"
                          >
                            <b>CONTACT</b>
                          </Link>
                          <Link
                            to="/account"
                            onClick={() => ".navbar-collapse".collapse("hide")}
                            class="nav-link"
                            data-toggle="collapse"
                            data-bs-target=".navbar-collapse"
                          >
                            <b class="amb-b">ACCOUNT</b>
                          </Link>
                          <Link
                            to="/preorder"
                            onClick={() => ".navbar-collapse".collapse("hide")}
                            class="nav-link"
                            data-toggle="collapse"
                            data-bs-target=".navbar-collapse"
                          >
                            <b>SHOP</b>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
      <div class="col-sm-4 ">
        <div class="contact-info">
						<div id="dep1">
							<ul class="menuo">
								<li><a href="/order">ORDERS</a></li>
								<li><a href="/subscription">SUBSCRIPTIONS</a></li>
								<li><a href="/billing">BILLING</a></li>
								<li><a href="/reward">REWARDS</a></li>
								<li><a href="/refer">REFER A FRIEND</a></li>
							</ul>
						</div>
					</div>
        </div>
        <br />
        <br />
        <br />
        <div class="col-sm-8 ">
          <div class="row">
            <div class="m-amb">
              <h3 class="bcm">Become an Ambassador!</h3>
              <div><p class="as-info">Fill out the information below to apply to become a Tecton™ Ambassador</p></div>
              <div class="cot-for">
                <div class="form-group">
                  <label class="control-label col-sm-2" for="fname"> Where did you hear about Tecton™</label>
                  <div class="col-sm-10">
                    <input type="text" class="formA-control" id="" name="about tecton" />
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-sm-2" for="email">Twitter Handle</label>
                  <div class="col-sm-10">
                    <input type="text" class="formA-control" id="" name="" />
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-sm-2" for="lname">Instagram Handle</label>
                  <div class="col-sm-10">
                    <input type="text" class="formA-control" id="" name="" />
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-sm-2" for="lname">TikTok Handle</label>
                  <div class="col-sm-10">
                    <input type="text" class="formA-control" id="" name="" />
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label col-sm-2" for="lname">Other Social Media Links</label>
                  <div class="col-sm-10">
                    <input type="email" class="formA-control" id="link" name="social link" />
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-sm-2" for="lname">(100 Words Why You Should Be A TECTON™ Ambassador)</label>
                  <div class="col-sm-10">
                    <input type="text" class="formA-control" id="tecton100" name="tecton100" />
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label col-sm-2" for="lname">Country</label>
                  <div class="col-sm-10">
                    {/* <DatalistInput */}
                    <input type="country" class="formA-control" id="country" name="country" />

                    {/* placeholder="Select your country"
    onSelect={(item) => set_country(item.value)}
    items={[
      { value: 'United states' },
  
    ]}
  /> */}
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <div class="fok-group">
                <Link to="/ambassador2" class=" btn-btn" type="submit">SUBMIT</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ambassador1;