import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import tectonglacier from "../images/productimages/Tecton_Glacier_4Pack (1).png"
import tectonglacier1 from "../images/productimages/Tecton_Glacier_Nutrition (2).png"
import tectonglacier2 from "../images/productimages/Tecton_Magma_4Pack (2).png"
import tectonglacier3 from "../images/productimages/Tecton_Magma_Nutrition.png"
import tectonglacier4 from "../images/productimages/Tecton_ProductDescription.png"
import tectonglacier5 from "../images/productimages/Tecton_SingleCans_Glacier.png"
import tectonglacier6 from "../images/productimages/Tecton_SingleCans_Magma.png"
function Coming(){
    return(
        <>
        {/* <div style={{height:"45vh"}}>
        <h2 style={{position:"fixed",left:"40%",top:"30%"}}>COMING SOON....</h2>
        </div> */}
        <img src={tectonglacier}></img>
        <img src={tectonglacier1}></img>
        <img src={tectonglacier2}></img>
        <img src={tectonglacier3}></img>
        <img src={tectonglacier4}></img>
        <img src={tectonglacier5}></img>
        <img src={tectonglacier6}></img>
        </>
    )
}


export default Coming;