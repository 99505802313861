import "../css/Fyr.css";
import { Link } from "react-router-dom";
import LOGOORANGE from "../images/TECTON beta site.png";
import LOGOSIDE from "../images/TECTON beta site side.png";
import Google from "../images/Iconone.png";
import facebook from "../images/icontwo.png";
import twitter from "../images/iconthree.png";
import React, { useState } from "react";
import axios from "axios";
import { isEmail } from "./utils/validation/Validation";
import {
  showErrMsg,
  showSuccessMsg,
} from "./utils/notification/Notification";

const initialState = {
  email: "",
  err: "",
  success: "",
};

function Fyr(){
  const [data, setData] = useState(initialState);

  const { email, err, success } = data;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value, err: "", success: "" });
  };

  const forgotPassword = async () => {
    if (!isEmail(email))
      return setData({ ...data, err: "Invalid emails.", success: "" });

    try {
      const res = await axios.post("http://localhost:5000/user/forgot", { email });

      return setData({ ...data, err: "", success: res.data.msg });
    } catch (err) {
      err.response.data.msg &&
        setData({ ...data, err: err.response.data.msg, success: "" });
    }
  };
    return(
        <div className="Fyr container">
          
<form>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label" style={{color:"orange"}}><h3><b>Forgot Your Password?</b></h3></label>

  </div>
  {err && showErrMsg(err)}
        {success && showSuccessMsg(success)}
  <div class="mb-2">
    <label for="exampleInputPassword1" class="form-label"><b>No problem! We're here to help.</b></label>
  </div>

  <div class="mb-4  ">
    <label for="exampleInputPassword1" class="form-label">Enter your email below and we can send you a password reset email.    </label>
  </div>
  <div class="col-lg-5 col-sm-12">
   Email
    <input type="email"
          name="email"
          value={email}
          onChange={handleChangeInput} id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline"/>
  </div>
  <br/>
   
  <button type="submit" class="btn px-5 py-2" style={{backgroundColor:"orange",color:"white"}} onClick={forgotPassword}>Verify your email</button><br/><br/>
  <label for="exampleInputPassword1" class="form-label" >Didn't receive the code? <span style={{color:"orange"}}>Resend email.</span></label>
</form>
        </div>
    )
}

export default Fyr;