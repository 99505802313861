import React from "react";
import LOGOORANGE from "../images/Tecton side by side white logo.png";
import LOGOSIDE from "../images/Tecton side by side white logo.png"
import TECHW from "../images/TECHW.png";
import "../css/form2.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import axios from "axios";
import countryList from "react-select-country-list";
import Select from "react-select";
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircle} from "@fortawesome/free-solid-svg-icons"; 
import BEGREAT  from '../images/BEGREAT.png';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {faCartShopping} from "@fortawesome/free-solid-svg-icons";
import Logo from "../images/Tecton side by side white logo.png";
import {
  Navbar,
  Container,
  NavDropdown,
  Offcanvas,
  Nav,
} from "react-bootstrap";

function Order() {
  useEffect(()=>{
    axios.get("https://restcountries.com/v3.1/all").then(res=>newdesh(res.data));
    
    axios.get("https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json").then(resp=>newrajya(resp.data));
  },[])
  const [state1, setstate1] = useState();
  const [state2, setstate2] = useState();
  const [state3, setstate3] = useState();
  const [state4, setstate4] = useState();
  const [state5, setstate5] = useState();
  const [state6, setstate6] = useState();
  const [state7, setstate7] = useState();
  const [state8, setstate8] = useState();
  const [state9, setstate9] = useState();
  const [state10, setstate10] = useState();
  const [state11, setstate11] = useState();
  const [state12, setstate12] = useState();
  const [state13, setstate13] = useState();
  const [state14, setstate14] = useState();
  const [state15, setstate15] = useState("");
  const[desh,newdesh]=useState();
  const[rajya,newrajya]=useState();
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [email, set_email] = useState("");
  const [where_did_you_hear_about_tecton, set_where_did_you_hear_about_tecton] =
    useState("");
  const [city, set_city] = useState("");
  const [state, set_state] = useState("");
  const [postal_code, set_postal_code] = useState("");
  const [country, set_country] = useState("");
  const [twitter_handle, set_twitter_handle] = useState("");
  const [instagram_handle, set_instagram_handle] = useState("");
  const [tiktok_handle, set_tiktok_handle] = useState("");
  const [other_social_media_link, set_other_social_media_link] = useState("");
  const [short_bio, set_short_bio] = useState("");
  const [street_address, set_street_address] = useState("");

  const submitForm = (e) => {
    e.preventDefault();
    axios
      .post("http://localhost:5000/ambassador", {
        first_name: first_name,
        last_name: last_name,
        email: email,
        where_did_you_hear_about_tecton: where_did_you_hear_about_tecton,
        city: city,
        state: state,
        postal_code: postal_code,
        country: country,
        twitter_handle: twitter_handle,
        instagram_handle: instagram_handle,
        tiktok_handle: tiktok_handle,
        other_social_media_link: other_social_media_link,
        short_bio: short_bio,
        street_address: street_address,
      })
      .then((result) => {
        set_city("");
        set_country("");
        set_email("");
        set_first_name("");
        set_last_name("");
        set_instagram_handle("");
        set_other_social_media_link("");
        set_twitter_handle("");
        set_tiktok_handle("");
        set_street_address("");
        set_postal_code("");
        set_short_bio("");
        set_where_did_you_hear_about_tecton("");
        set_state("");
        console.log(result);
        if (result.data[0] === "success") {
          setstate15(
            <div class="alert alert-success" role="alert">
              Thank you for submitting the form!!
            </div>
          );
        }
      });

    // setstate15(<span style={{color:"green"}}>From submitted!!</span>)
  };
  return (
    <div>
        <div className="header" style={{backgroundColor:"black"}}>
      <div className="header_logo">
      <Link to="/" onClick={()=>('.navbar-collapse').collapse('hide')}> <img src={Logo} alt="Logo da Marca"></img></Link>
      </div>
      <div className="header_center">
      <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopLeftRadius:"22px",borderBottomLeftRadius:"22px"}}>BE GREAT</p></Link>
      <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">AMBASSADOR</p></Link>
      <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">SCIENCE</p></Link>
      <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">CONTACT</p></Link>
      <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">ACCOUNT</p></Link>
      <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" ><NavDropdown title="SHOP">
              <NavDropdown.Item>SHOP ALL</NavDropdown.Item>
              <NavDropdown.Item>TECTON</NavDropdown.Item>
              <NavDropdown.Item>MERCHANDISE</NavDropdown.Item>
            </NavDropdown></p></Link>
            <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopRightRadius:"22px",borderBottomRightRadius:"22px"}}><FontAwesomeIcon icon={faCartShopping} style={{color:"white",fontWeight:"bolder"}}/></p></Link>
        
      </div>

      {/* <div className="header_right">
         <p className="ghostItem">Shop</p>
        <p className="ghostItem">Account</p> 
        <p className="ghostItem">MENU</p>
      </div> */}

      <div className="revealGhostItems">
        <Navbar className="cont" expand={false}>
          <Container fluid>
          <Link to="/preorder"><span><FontAwesomeIcon icon={faCartShopping} style={{color:"white",fontWeight:"bolder",fontSize:"30px"}}/></span></Link>
            <Navbar.Toggle href="#" className="menu">
            <span><FontAwesomeIcon icon={faBars} style={{color:"white",fontWeight:"bolder"}}/></span>
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              <Nav style={{textAlign:"right"}}>
            <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">BE GREAT</Link>
            <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">AMBASSADOR</Link>
            <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">SCIENCE</Link>
            <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">CONTACT</Link>
            <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">ACCOUNT</Link>
            <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><NavDropdown title="SHOP">
              <NavDropdown.Item>SHOP ALL</NavDropdown.Item>
              <NavDropdown.Item>TECTON</NavDropdown.Item>
              <NavDropdown.Item>MERCHANDISE</NavDropdown.Item>
            </NavDropdown></Link>
            <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">ACCOUNT</Link>
            {/* <NavDropdown
              title="More"
              id="offcanvasNavbarDropdown"
              className="listItem"
            >
              <NavDropdown.Item href="#action3">
                Cybertruck
              </NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Roadster
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">Semi</NavDropdown.Item>
              <NavDropdown.Item>News</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="English">
              <NavDropdown.Item>Portuguese-Br</NavDropdown.Item>
              <NavDropdown.Item>Portuguese-Pt</NavDropdown.Item>
              <NavDropdown.Item>Italian</NavDropdown.Item>
              <NavDropdown.Item>Spanish</NavDropdown.Item>
              <NavDropdown.Item>French</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>
    </div>

      <div class="outT">
        <div class="board1">
          <div class="conten">
            <div class="pret-text">
              <div class="well">
              <h1 class="order-tex">Become a Tecton Ambassador</h1>
              </div>
            </div>
            <div class="first-part" style={{ backgroundColor: "orange" }}>

<b style={{fontSize:"23px",fontWeight:"700"}}>Tecton™</b>   is a purpose driven company with a mission to help people discover the extraordinary

  in themselves and in others. <b style={{fontSize:"23px",fontWeight:"700"}}>Tecton™</b> is the world’s first “focused performance” beverage that

   contains a nature-identical ketone produced through a proprietary and natural enzymatic process. 

   Read more about the science behind<b style={{fontSize:"23px",fontWeight:"700"}}> Tecton™</b>.<br />

<br />

 The company has been co-founded by a Special Forces Combat Medic and the son of a Fighter Pilot. 

 They both believe that we need to take care of veterans, especially those who suffer from the 

 consequences of having been in war. One percent of our revenues go to nonprofits that help disabled 

  veterans and their families get back on their feet.<br />

<br />

 As an ambassador, you will get first access to our newest products designed to help with recovery, weight loss,

 neuroprotection, cognitive focus and more. <br />

 <br/>

Are you an ambassador for living life to the fullest? Being healthy, being present, and showing up in the world at your very best? <br /><br/>

 Then connect with the founders of<b style={{fontSize:"23px",fontWeight:"700"}}> Tecton™</b> and learn more about how we can work together. Help us make a difference.<br />

</div>
            <br />
            <div class="second-part">
              <div class="sant">
                <span>
                  (<span style={{ color: "red" }}>*</span>)
                </span>
                Mandatory fields.
                <p class="am-bass"> Contact Information</p>
                <div class="in-de">
                  <form onSubmit={submitForm}>
                  <span style={{ color: "red" }}>*</span>First Name:{" "}
                    <input
                      type="text"
                      value={first_name}
                      onChange={(e) => set_first_name(e.target.value)}
                      class="text-line1"
                      style={{}}
                    />
                    {state1}
                    <br />
                    <span style={{ color: "red" }}>*</span>Last Name:{" "}
                    <input
                      type="text"
                      class="text-line2"
                      value={last_name}
                      onChange={(e) => set_last_name(e.target.value)}
                      style={{}}
                    />
                    {state2}
                    <br />
                    <span style={{ color: "red" }}>*</span>Email:{" "}
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => set_email(e.target.value)}
                      class="text-line3"
                      style={{}}
                    />
                    {state3}
                    <br />
                    <span style={{ color: "red" }}>*</span>
                    Where did you hear about Tecton™?
                   {" "}
                    <input
                      type="text"
                      value={where_did_you_hear_about_tecton}
                      onChange={(e) =>
                        set_where_did_you_hear_about_tecton(e.target.value)
                      }
                      class="text-line4"
                      style={{}}
                    />
                    {state4}
                    <br />
                    <br />
                    <p class="am-bass">Social Media Presence</p>
                    Twitter Handle:{" "}
                    <input
                      type="text"
                      value={twitter_handle}
                      onChange={(e) => set_twitter_handle(e.target.value)}
                      class="text-line5"
                      style={{}}
                    />
                    {state5}
                    <br />
                    Instagram Handle:{" "}
                    <input
                      type="text"
                      value={instagram_handle}
                      onChange={(e) => set_instagram_handle(e.target.value)}
                      class="text-line6"
                      style={{}}
                    />
                    {state6}
                    <br />
                    TikTok Handle:{" "}
                    <input
                      type="text"
                      value={tiktok_handle}
                      onChange={(e) => set_tiktok_handle(e.target.value)}
                      class="text-line7"
                      style={{}}
                    />
                    {state7}
                    <br />
                    Other Social Media Links:{" "}
                    <input
                      type="text"
                      value={other_social_media_link}
                      onChange={(e) =>
                        set_other_social_media_link(e.target.value)
                      }
                      class="text-line8"
                      style={{}}
                    />
                    {state8}
                    <br />
                    <br />
                    <p class="am-bass">Short Bio</p>
                    <span style={{ color: "red" }}>*</span>
                    (100 Words Why You Should Be A TECTON™ Ambassador)
                    :{" "}
                    <input
                      type="text"
                      value={short_bio}
                      onChange={(e) => set_short_bio(e.target.value)}
                      class="text-liner"
                      style={{}}
                    />
                    {state9}
                    {/* <input type="text" class="text-lines" style={{}} />
                    <input type="text" class="text-linet" style={{}} />
                    <input type="text" class="text-lineu" style={{}} />
                    <input type="text" class="text-linev" style={{}} />
                    <input type="text" class="text-linew" style={{}} /> <br /> */}{" "}
                    <br />
                    <span style={{ color: "red" }}>*</span>Street Address:{" "}
                    <input
                      type="text"
                      value={street_address}
                      onChange={(e) => set_street_address(e.target.value)}
                      class="text-line9"
                    />
                    {state10}
                    <br />
                    <span style={{ color: "red" }}>*</span>City:{" "}
                    <input
                      type="text"
                      value={city}
                      onChange={(e) => set_city(e.target.value)}
                      class="text-line10"
                    />
                    {state11}
                    <br />
                    {/* State<span style={{ color: "red" }}>*</span>:{" "}
                    <input
                      type="text"
                      value={state}
                      onChange={(e) => set_state(e.target.value)}
                      class="text-line11"
                    /> */}
                    <span style={{ color: "red" }}>*</span>State:
                    <DatalistInput
    placeholder="select your state"
    onSelect={(item) => set_state(item.value1)}
    items={[
      { value1: 'AK', value: 'Alaska'},
      { value1: 'TX', value: 'Texas'},
      { value1: 'AL', value: 'Alabama'},
      { value1: 'AR', value: 'Arkansas'},
      { value1: 'AZ', value: 'Arizona'},
      { value1: 'CA', value: 'California'},
      { value1: 'CO', value: 'Colorado'},
      { value1: 'CT', value: 'Connecticut'},
      { value1: 'DC', value: 'DistrictofColumbia'},
      { value1: 'DE', value: 'Delaware'},
      { value1: 'FL', value: 'Florida'},
      { value1: 'GA', value: 'Georgia'},
      { value1: 'HI', value: 'Hawaii'},
      { value1: 'IA', value: 'Iowa'},
      { value1: 'ID', value: 'Idaho'},
      { value1: 'IL', value: 'Illinois'},
      { value1: 'IN', value: 'Indiana'},
      { value1: 'KS', value: 'Kansas'},
      { value1: 'KY', value: 'Kentucky'},
      { value1: 'LA', value: 'Louisiana'},
      { value1: 'MA', value: 'Massachusetts'},
      { value1: 'MD', value: 'Maryland'},
      { value1: 'ME', value: 'Maine'},
      { value1: 'MI', value: 'Michigan'},
      { value1: 'MN', value: 'Minnesota'},
      { value1: 'MO', value: 'Missouri'},
      { value1: 'MS', value: 'Mississippi'},
      { value1: 'MT', value: 'Montana'},
      { value1: 'NC', value: 'NorthCarolina'},
      { value1: 'ND', value: 'NorthDakota'},
      { value1: 'NE', value: 'Nebraska'},
      { value1: 'NH', value: 'NewHampshire'},
      { value1: 'NJ', value: 'NewJersey'},
      { value1: 'NM', value: 'NewMexico'},
      { value1: 'NV', value: 'Nevada'},
      { value1: 'NY', value: 'NewYork'},
      { value1: 'OH', value: 'Ohio'},
      { value1: 'OK', value: 'Oklahoma'},
      { value1: 'OR', value: 'Oregon'},
      { value1: 'PA', value: 'Pennsylvania'},
      { value1: 'RI', value: 'RhodeIsland'},
      { value1: 'SC', value: 'SouthCarolina'},
      { value1: 'SD', value: 'SouthDakota'},
      { value1: 'TN', value: 'Tennessee'},
      { value1: 'TX', value: 'Texas'},
      { value1: 'UT', value: 'Utah'},
      { value1: 'VA', value: 'Virginia'},
      { value1: 'VT', value: 'Vermont'},
      { value1: 'WA', value: 'Washington'},
      { value1: 'WI', value: 'Wisconsin'},
      { value1: 'WV', value: 'WestVirginia'},
      { value1: 'WY', value: 'Wyoming'}
      ]}
  />
                    {state12}
                    <br />
                    <span style={{ color: "red" }}>*</span>Postal Code:{" "}
                    <input
                      type="number"
                      value={postal_code}
                      onChange={(e) => set_postal_code(e.target.value)}
                      class="text-line12"
                    />
                    {state13}
                    <br />
                    <span style={{ color: "red" }}>*</span>Country
                   {/* <select value={country} onChange={(e) => set_country(e.target.value)}>
                    {desh?.map((val,key)=><option id={key}>{val?.name?.common}</option>)}

                   </select> */}
                     <DatalistInput
    placeholder="Select your country"
    onSelect={(item) => set_country(item.value1)}
    items={[
      { value1: 'U.S.' ,value: 'United States' },
  
    ]}/>
                    <br />
                    <div class="Dgreat">
                      <div class="bP">
                      <img class="ft-image" src={BEGREAT} ></img><br/>
                        <input
                          class="down-button-bet"
                          type="submit"
                          value="Submit"
                          disabled={
                            !first_name ||
                            !last_name ||
                            !email ||
                            !where_did_you_hear_about_tecton ||
                            !short_bio ||
                            !street_address ||
                            !city ||
                            !state ||
                            !country ||
                            !postal_code
                          }
                        ></input>{" "}
                        <br />
                        {state15}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order;
