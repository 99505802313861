import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
// import { LinkContainer } from 'react-router-bootstrap';
import '../css/Header.css'
import LOGOORANGE from '../images/Tecton side by side white logo.png';
import TECHW from '../images/TECHW.png';
import LOGOSIDE from "../images/Tecton side by side white logo.png"
import LOGO from "../images/LOGO.png"
import ScrollToTop from "./ScrollToTop";
import ham from '../images/hamburger-menu.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {faBars} from "@fortawesome/free-solid-svg-icons"; 
import Logo from "../images/Tecton side by side white logo.png";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Container,
  NavDropdown,
  Offcanvas,
  Nav,
} from "react-bootstrap";

// function useWindowSize(){
//   const[size,setsize]=useState([window.innerHeight,window.innerWidth]);
//   useEffect(()=>{
//     const handleResize = () =>{
//       setsize([window.innerHeight,window.innerWidth]);
//     };
//     window.addEventListener("resize",handleResize);
//    return()=>{
//      window.removeEventListener("resize",handleResize)
//    };
//   },[]);
//   return size;
// }

const Header = () => {
  const[nav,setnav]=useState("transparent");
  
    const changebg = ()=>{
      if(window.scrollY > 100){
        setnav("black")}
      else(
        setnav("transparent")
      )};
        useEffect(()=>{
    
          window.addEventListener("scroll",changebg)
          
        });
    return(
    <>
    
    <ScrollToTop/>
      
      
    <div className="header" style={{backgroundColor:`${nav}`}}>
   <div className="header_logo">
        <Link to="/" onClick={()=>('.navbar-collapse').collapse('hide')}> <img src={Logo} alt="Logo da Marca"></img></Link>
        </div>
        <div className="header_center">
        <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopLeftRadius:"22px",borderBottomLeftRadius:"22px"}}>BE GREAT</p></Link>
        <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">AMBASSADOR</p></Link>
        <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">SCIENCE</p></Link>
        <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">CONTACT</p></Link>
        <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">ACCOUNT</p></Link>
        <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <NavDropdown title={<div>
          <img src={LOGO} width="50px"></img>
        </div>} id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">SHOP ALL</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">TECTON</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">MERCHANDISE</NavDropdown.Item>
            {/* <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
          </NavDropdown></Link>
              <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopRightRadius:"22px",borderBottomRightRadius:"22px"}}><FontAwesomeIcon icon={faCartShopping} style={{color:"white",fontWeight:"bolder"}}/></p></Link>
        </div>
  
  
  {/* <div className="header_right">
     <p className="ghostItem">Shop</p>
    <p className="ghostItem">Account</p> 
    <p className="ghostItem">MENU</p>
  </div> */}
  
  <div className="revealGhostItems">
    <Navbar className="cont" expand={false}>
      <Container fluid>
      <Link to="/preorder"><span><FontAwesomeIcon icon={faCartShopping} style={{color:"white",fontWeight:"bolder",fontSize:"30px"}}/></span></Link>
        <Navbar.Toggle href="#" className="menu">
        <span><FontAwesomeIcon icon={faBars} style={{color:"white",fontWeight:"bolder"}}/></span>
        </Navbar.Toggle>
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav style={{textAlign:"right"}}>
              <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">BE GREAT</Link>
              <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">AMBASSADOR</Link>
              <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">SCIENCE</Link>
              <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">CONTACT</Link>
              <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">ACCOUNT</Link>
              <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><NavDropdown title="SHOP">
                <NavDropdown.Item>SHOP ALL</NavDropdown.Item>
                <NavDropdown.Item>TECTON</NavDropdown.Item>
                <NavDropdown.Item>MERCHANDISE</NavDropdown.Item>
              </NavDropdown></Link>
              {/* <NavDropdown
                title="More"
                id="offcanvasNavbarDropdown"
                className="listItem"
              >
                <NavDropdown.Item href="#action3">
                  Cybertruck
                </NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Roadster
                </NavDropdown.Item>
                <NavDropdown.Item href="#action5">Semi</NavDropdown.Item>
                <NavDropdown.Item>News</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="English">
                <NavDropdown.Item>Portuguese-Br</NavDropdown.Item>
                <NavDropdown.Item>Portuguese-Pt</NavDropdown.Item>
                <NavDropdown.Item>Italian</NavDropdown.Item>
                <NavDropdown.Item>Spanish</NavDropdown.Item>
                <NavDropdown.Item>French</NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  </div>
  </div>
    
    </>
  );
  }
  
  export default Header;