import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import telescope from '../images/science.new.jpg';
import "../css/Science.css"
import { useState } from 'react';
import redcan from '../images/MAGMAN.png';
import bluecan from '../images/GLACIERN.png';
import blueblack from '../images/glacier back.png';
import redback from '../images/magma back.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlassMinus } from '@fortawesome/free-solid-svg-icons';




function Science() {

  const [state, setstate] = useState(redcan);
  const [state2, setstate2] = useState("GLACIER");
  const [state3, setstate3] = useState((<span style={{ fontWeight: "bold" }}>MAGMA</span>));
  const [state4, setstate4] = useState("red");
  const [state5, setstate5] = useState("");
  const [state6, setstate6] = useState("");
  const [state7, setstate7] = useState("");
  const[state9,setstate9]=useState("hover-zoom");
  const[state10,setstate10]=useState("");
  const[state11,setstate11]=useState("CLICK IMAGE TO ZOOM IN");
      

  return (
    <div>
      <section class="space-one">

        <Carousel showThumbs={false} showIndicators={false} showStatus={false} thumbWidth="19%" autoPlay={false} showArrows={false} infiniteLoop={true} interval={3000} showArrows={false}>
          <div>
            <div id="image-tel1">
             

              <div class="image-space">
                <div class="IM-texts" >
                   <p>Big changes start small.</p>
                </div>

                <Link to="/preorder"><button class="tex-white" >Pre-Order Now</button></Link>
              </div>
            </div>
          </div>
         </Carousel>

      </section>

      <section class="space-two">

        <div id="desk" class="row">
          <div class="col-sm-6  space-two-one">
            <br />
            <br />
              <div id="level1">
            <p class="cell" > Cellular-level small.</p>
          
            <br />
            <p   class="mit" >The mitochondria in your cells<br />
              are like the tectonic plates of the Earth.<br />
              Energize them,<br />
              and you can move mountains.<b><Link to="/be-great" style={{textDecoration:"none"}}><span style={{ fontSize: "14px",color:"white" }}>&nbsp;&nbsp;&nbsp;MORE &gt;&gt;</span></Link></b> <br />
            </p>
            </div>
            <div id="level2">
            <p class="cell" > Cellular-level small.</p>
          
            <br />
            <p   class="mit" >The mitochondria in your cells
              are like the tectonic plates of the Earth.<br />
              Energize them,<br />
              and you can move mountains.<b><Link to="/be-great" style={{textDecoration:"none"}}><span style={{ fontSize: "14px",color:"white" }}>&nbsp;&nbsp;&nbsp;MORE &gt;&gt;</span></Link></b> <br />
            </p>
            </div>
            <br />
            <br />
            <br />
            <div class="b-ton">
            <Link to="/preorder"><button class="space-two-button" >Pre-Order Now</button></Link>
            <Link to="/be-great"><button class="space-three-button" >Learn More</button></Link>
            </div>
          </div>
          <div class="col-sm-6  space-two-two">
            <div className='img-wrapper'>
              <br />
              
              <div id="cane1">
              <img class="imgS" src={state} height="460" className={state9} onClick={()=>{
                setstate9("hover-zoom-in")
                   }}
                    onMouseLeave={()=>{
                     setstate9("hover-zoom")
                   
                     }}></img>
                       <br/>
                       <br/>
</div>
<div id="cane2">
                   <img class="img1" src={state} height="480px" className={state10}   ></img> 
                   <br/>
                   <br/>
                  
                   </div>
            </div>
            <div class="row">
            <div class="col-6" style={{textAlign:"right"}}><FontAwesomeIcon onClick={()=>{
        if(state===blueblack){
          setstate(bluecan);
          setstate6("blue");
          setstate7("")
        }    
        
        if(state===redcan){
        setstate(bluecan);
        setstate6("blue");
        setstate7("");
        setstate4("");
        setstate5("");
        setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>)
        }
         
        if(state===redback){
          setstate(bluecan);
          setstate6("blue");
          setstate7("");
          setstate4("");
          setstate5("");
          setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>);
          setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>)
          }

      }
      
      } icon={faCircle} style={{cursor:"pointer",color:`${state6}`}}/>&nbsp;&nbsp;&nbsp;
      <FontAwesomeIcon onClick={()=>{
      if(state===bluecan){
        setstate(blueblack);
        setstate7("blue");
        setstate6("")
      }

      if(state===redcan){
        setstate(blueblack);
        setstate6("");
        setstate7("blue");
        setstate4("");
        setstate5("");
        setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>)

      }

      if(state===redback){
        setstate(blueblack);
        setstate6("");
        setstate7("blue");
        setstate4("");
        setstate5("");
        setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>)

      }
      
      }
      
      } icon={faCircle} style={{cursor:"pointer",color:`${state7}`}}/>&nbsp;&nbsp;</div>

        

      <div class="col-6"><div class="col-6" style={{textAlign:"left"}} >&nbsp;&nbsp;<FontAwesomeIcon onClick={()=>{
      
      if(state===redback){
        setstate(redcan);
        setstate4("red");
        setstate5("");
      }   

      if(state===bluecan){
        setstate(redcan);
        setstate6("");
        setstate7("");
        setstate4("red");
        setstate5("");
        setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>)
        }

        if(state===blueblack){
          setstate(redcan);
          setstate6("");
          setstate7("");
          setstate4("red");
          setstate5("");
          setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>);
          setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>)
          }
      
      
      }
      
      } icon={faCircle} style={{cursor:"pointer",color:`${state4}`}}/>&nbsp;&nbsp;&nbsp;
      <FontAwesomeIcon onClick={()=>{
       if(state===redcan){
        setstate(redback);
        setstate5("red");
        setstate4("")
      }

      if(state===bluecan){
        setstate(redback);
        setstate6("");
        setstate7("");
        setstate4("");
        setstate5("red");
        setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>)
        }
      
        if(state===blueblack){
          setstate(redback);
          setstate6("");
          setstate7("");
          setstate4("");
          setstate5("red");
          setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>);
          setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>)
          }

      }
      
      } icon={faCircle} style={{cursor:"pointer",color:`${state5}`}}/></div></div>
      </div>
   <div  id="cane1"  style={{color:"white"}} >
   <span style={{cursor:"pointer"}} onClick={(()=>{return (setstate(bluecan),setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>),setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>),setstate4(""),setstate5(""),setstate6("blue"),setstate7(""))})}>{state2}</span> 
     <span style={{fontSize:"30px",padding:"10px"}}>|</span> 
     <span style={{cursor:"pointer"}} onClick={(()=>{return (setstate(redcan),setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>),setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>),setstate6(""),setstate7(""),setstate4("red"),setstate5(""))})}>{state3}</span> 
  
   </div>

   <div  id="cane2" style={{color:"white"}} >
   <span style={{cursor:"pointer"}} onClick={(()=>{return (setstate(bluecan),setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>),setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>),setstate4(""),setstate5(""),setstate6("blue"),setstate7(""))})}>{state2}</span> 
     <span style={{fontSize:"30px",padding:"10px"}}>|</span> 
     <span style={{cursor:"pointer"}} onClick={(()=>{return (setstate(redcan),setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>),setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>),setstate6(""),setstate7(""),setstate4("red"),setstate5(""))})}>{state3}</span> 
      {/* <div onClick={()=>{return setstate10("hover-zoom"),setstate11("CLICK IMAGE TO ZOOM IN")}}>{state11}</div> */}
    <div class="row"> 
      <div class="col-6" style={{textAlign:"right"}} onClick={()=>{return setstate10("hover-zoom-in-mobile")}}><FontAwesomeIcon icon={faMagnifyingGlassPlus} />&nbsp;&nbsp;</div>  
      <div class="col-6" style={{textAlign:"left"}} onClick={()=>{return setstate10("hover-zoom")}}>&nbsp;&nbsp;<FontAwesomeIcon icon={faMagnifyingGlassMinus}/></div>
      </div>
   </div>
  </div>
        </div>
 
      </section>


    </div>
  )
}


export default Science; 