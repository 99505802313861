import React, { useEffect } from "react";
import logo from './logo.svg';
import './App.css';
import axios from "axios";
import Header from './components/Header';
import Body from './components/Body';
import { BrowserRouter as Router} from 'react-router-dom';
import Footer from './components/Footer';
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchLogin,
  fetchUser,
  dispatchGetUser,
} from "./redux/actions/authAction";

function App() {
  const dispatch = useDispatch();
    const token = useSelector((state) => state.token);
    const auth = useSelector((state) => state.auth);
  
    useEffect(() => {
      const firstLogin = localStorage.getItem("firstLogin");
      try {
        if (firstLogin) {
          const getToken = async () => {
            const res = await axios.post("http://localhost:5000/user/refresh_token", null);
            console.log(res);
            dispatch({ type: "GET_TOKEN", payload: res.data.access_token });
          };
          getToken();
        }
      } catch (err) {
        console.log(err);
      }
    }, [auth.isLogged, dispatch]);
  
    useEffect(() => {
      if (token) {
        const getUser = () => {
          dispatch(dispatchLogin());
  
          return fetchUser(token).then((res) => {
            console.log(res);
            dispatch(dispatchGetUser(res));
          });
        };
        getUser();
      }
    }, [token, dispatch]);
  
  
  return (<>
    <Router>
      <div>
    <Header/>
    <Body/>
    </div>
    </Router>
    <Footer/>
    </>
  );
}

export default App;
