import React, { useState, useEffect } from "react";
import { useParams,Link } from "react-router-dom";
import axios from "axios";
import {
  showErrMsg,
  showSuccessMsg,
} from "./utils/notification/Notification";
import {
  Navbar,
  Container,
  NavDropdown,
  Offcanvas,
  Nav,
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircle} from "@fortawesome/free-solid-svg-icons"; 
import BEGREAT  from '../images/BEGREAT.png';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import Logo from "../images/Tecton side by side white logo.png";

function ActivationEmail() {
  const { activation_token } = useParams();
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (activation_token) {
      const activationEmail = async () => {
        try {
          const res = await axios.post("http://localhost:5000/user/activation", {
            activation_token, 
          });
          setSuccess(res.data.msg);
        } catch (err) {
          err.response.data.msg && setErr(err.response.data.msg);
        }
      };
      activationEmail();
    }
  }, [activation_token]);

  return (
    <>
        <div className="header" style={{backgroundColor:"black"}}>
      <div className="header_logo">
      <Link to="/" onClick={()=>('.navbar-collapse').collapse('hide')}> <img src={Logo} alt="Logo da Marca"></img></Link>
      </div>
      <div className="header_center">
      <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopLeftRadius:"22px",borderBottomLeftRadius:"22px"}}>BE GREAT</p></Link>
      <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">AMBASSADOR</p></Link>
      <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">SCIENCE</p></Link>
      <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">CONTACT</p></Link>
      <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">ACCOUNT</p></Link>
      <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopRightRadius:"22px",borderBottomRightRadius:"22px"}}>SHOP</p></Link>
      </div>

      {/* <div className="header_right">
         <p className="ghostItem">Shop</p>
        <p className="ghostItem">Account</p> 
        <p className="ghostItem">MENU</p>
      </div> */}

      <div className="revealGhostItems">
        <Navbar className="cont" expand={false}>
          <Container fluid>
            <Navbar.Toggle href="#" className="menu">
            <span><FontAwesomeIcon icon={faBars} style={{color:"white",fontWeight:"bolder"}}/></span>
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              <Nav style={{textAlign:"right"}}>
            <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">BE GREAT</Link>
            <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">AMBASSADOR</Link>
            <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">SCIENCE</Link>
            <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">CONTACT</Link>
            <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">ACCOUNT</Link>
            <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">SHOP</Link>
            {/* <NavDropdown
              title="More"
              id="offcanvasNavbarDropdown"
              className="listItem"
            >
              <NavDropdown.Item href="#action3">
                Cybertruck
              </NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Roadster
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">Semi</NavDropdown.Item>
              <NavDropdown.Item>News</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="English">
              <NavDropdown.Item>Portuguese-Br</NavDropdown.Item>
              <NavDropdown.Item>Portuguese-Pt</NavDropdown.Item>
              <NavDropdown.Item>Italian</NavDropdown.Item>
              <NavDropdown.Item>Spanish</NavDropdown.Item>
              <NavDropdown.Item>French</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>
    </div>
        <div className="Signinthree container">
          
<form>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label" style={{color:"orange"}}><h3><b>Sign up</b></h3></label>
  </div>
  <div class="mb-2">
    <label for="exampleInputPassword1" class="form-label"><b>Verification Done ✅</b></label>

  </div>

  <div class="mb-4  ">
  <label for="exampleInputPassword1" class="form-label">Click the button below to login into your account.</label>
  </div>

  {/* <div class="mb-2">
    <label for="exampleInputPassword1" class="form-label"><b>Verification Code</b></label>
  </div> */}
  {/* <div class="vbox">
      <input name='code' class='code-input' required/>
      <input name='code' class='code-input' required/>
      <input name='code' class='code-input' required/>
      <input name='code' class='code-input' required/>
      <input name='code' class='code-input' required/>
      <input name='code' class='code-input' required/>
    </div> */}
    <br/>
    <Link to="/login"><button type="submit" class="btn px-5 py-2" style={{backgroundColor:"orange",color:"white"}}>LETS GO</button></Link>
  <div class="mt-3">
    {/* <label for="exampleInputPassword1" class="form-label" >Didn't receive the code? <span style={{color:"orange"}}>Resend email.</span></label> */}

  </div>
</form>
        </div>
        </>
  );
}

export default ActivationEmail;
