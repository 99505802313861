import LOGO from "../images/LOGO.png";
import "../css/Footer.css";
import social from "../images/social.png";
import footer from "../images/footerimage.jpg";
import { Link } from "react-router-dom";
import facebook_icon from "../images/facebook_icon.svg";
import twitter_icon from "../images/twitter_icon.svg";
import instagram_icon from "../images/instagram_icon.svg";
import linkedin_icon from "../images/linkedin_icon.svg";
import BEGREAT  from '../images/BEGREAT.png';
function Footer() {
  return (
    <div>
      <div id="bg" class="row" style={{ paddingBottom: "20px" }}>
        <div id="footer-one" class="col-sm-6">
          <div class="footer-one-text">
            All humans have (extra)ordinary potential. This can
            <br /> manifest in little actions of everyday kindness or great
            <br /> new discoveries.
          </div>
          <br />
          <br />
          <br />
          <div class="row">
            <div class="col-sm-12 col-xs-12 col-md-12 col-lg-6  footer-one-one">
              <h6>
                <b>PRODUCT</b>
              </h6>
              <a
                href="/coming"
                style={{ textDecoration: "none", color: "black" }}
              >
                <h6>PRODUCT</h6>
              </a>
              <a
                href="/preorder"
                style={{ textDecoration: "none", color: "black" }}
              >
                <h6>SHOP</h6>
              </a>
              <a
                href="/science"
                style={{ textDecoration: "none", color: "black" }}
              >
                <h6>SCIENCE</h6>
              </a>
              <a
                href="/coming"
                style={{ textDecoration: "none", color: "black" }}
              >
                <h6>SITEMAP</h6>
              </a>
              <a
                href="/coming"
                style={{ textDecoration: "none", color: "black" }}
              >
                <h6>TERMS OF SERVICE</h6>
              </a>
            </div>
            <div class="col-sm-12 col-xs-12 col-md-12 col-lg-6 footer-one-two">
              <h6>
                <b>ABOUT</b>
              </h6>
              <a
                style={{ textDecoration: "none", color: "black" }}
                href="/true-purpose"
              >
                <h6>MISSION</h6>
              </a>
              <a
                style={{ textDecoration: "none", color: "black" }}
                href="/ambassador"
              >
                {" "}
                <h6>BE AN AMBASSADOR</h6>
              </a>
              <a
                href="/careers"
                style={{ textDecoration: "none", color: "black" }}
              >
                <h6>CAREERS</h6>
              </a>
              <a
                href="/coming"
                style={{ textDecoration: "none", color: "black" }}
              >
                <h6>CONTACT</h6>
              </a>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div class="footer-one-bottom">
            ©2022 Tecton. Being great means doing the right thing.
          </div>
        </div>
        <div class="col-sm-6 footer-two" style={{ textAlign: "center" }}>
          <img src={LOGO} width="140px"></img>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="be">

<img class="ft-image" src={BEGREAT} ></img>

</div>
          <br className="remove" />
          <br className="remove" />

          <br className="remove" />
          <br className="remove" />
          <div className="justify-content-center">
            <a
              href="https://www.facebook.com/Tecton-101116088857657/"
              target="_blank"
            >
              <img class="img2" src={facebook_icon} alt="facebook_icon"></img>
            </a>
            <a href="https://twitter.com/tectonlife" target="_blank">
              <img class="img2" src={twitter_icon} alt="twitter_icon"></img>
            </a>
            <a href="https://www.instagram.com/tectonlife/" target="_blank">
              <img class="img2" src={instagram_icon} alt="instagram_icon"></img>
            </a>
            <a
              href="https://www.linkedin.com/showcase/tectonlife/about/"
              target="_blank"
            >
              <img class="img2" src={linkedin_icon} alt="linkedin_icon"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
