import React from "react";
import LOGOORANGE from "../images/Tecton side by side white logo.png";
import LOGOSIDE from "../images/Tecton side by side white logo.png"
import TECHW from "../images/TECHW.png";
import "../css/Signintwo.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import countryList from "react-select-country-list";
import Select from "react-select";
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircle} from "@fortawesome/free-solid-svg-icons"; 
import BEGREAT  from '../images/BEGREAT.png';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import Logo from "../images/Tecton side by side white logo.png";
import {
  Navbar,
  Container,
  NavDropdown,
  Offcanvas,
  Nav,
} from "react-bootstrap";
import {
  showErrMsg,
  showSuccessMsg,
} from "./utils/notification/Notification";
import {
  isEmpty,
  isEmail,
  isLength,
  isMatch,
} from "./utils/validation/Validation";

const initialState = {
  first_name: "",
  last_name: "",
  gender: "",
  email: "",
  password: "",
  cf_password: "",
  err: "",
  success: "",
};


function Signintwo(){
  const [user, setUser] = useState(initialState);
  const history=useNavigate();

  const {
    first_name,
    last_name,
    gender,
    email,
    password,
    cf_password,
    err,
    success,
  } = user;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value, err: "", success: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmpty(first_name) || isEmpty(password))
      return setUser({
        ...user,
        err: "Please fill in all fields.",
        success: "",
      });
    if (isEmpty(last_name))
      return setUser({
        ...user,
        err: "Please fill in all fields.",
        success: "",
      });
    if (isEmpty(gender))
      return setUser({
        ...user,
        err: "Please fill in all fields.",
        success: "",
      });
    if (!isEmail(email))
      return setUser({ ...user, err: "Invalid emails.", success: "" });

    if (isLength(password))
      return setUser({
        ...user,
        err: "Password must be at least 6 characters.",
        success: "",
      });

    if (!isMatch(password, cf_password))
      return setUser({ ...user, err: "Password did not match.", success: "" });

    try {
      const res = await axios.post("http://localhost:5000/user/register", {
        first_name,
        last_name,
        gender,
        email,
        password,
      });

      setUser({ ...user, err: "", success: res.data.msg });
      history("/signinthree")
    } catch (err) {
      err.response.data.msg &&
        setUser({ ...user, err: err.response.data.msg, success: "" });
    }
  };

    return(
      <>
        <div className="header" style={{backgroundColor:"black"}}>
      <div className="header_logo">
      <Link to="/" onClick={()=>('.navbar-collapse').collapse('hide')}> <img src={Logo} alt="Logo da Marca"></img></Link>
      </div>
      <div className="header_center">
      <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopLeftRadius:"22px",borderBottomLeftRadius:"22px"}}>BE GREAT</p></Link>
      <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">AMBASSADOR</p></Link>
      <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">SCIENCE</p></Link>
      <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">CONTACT</p></Link>
      <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">ACCOUNT</p></Link>
      <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopRightRadius:"22px",borderBottomRightRadius:"22px"}}>SHOP</p></Link>
      </div>

      {/* <div className="header_right">
         <p className="ghostItem">Shop</p>
        <p className="ghostItem">Account</p> 
        <p className="ghostItem">MENU</p>
      </div> */}

      <div className="revealGhostItems">
        <Navbar className="cont" expand={false}>
          <Container fluid>
            <Navbar.Toggle href="#" className="menu">
            <span><FontAwesomeIcon icon={faBars} style={{color:"white",fontWeight:"bolder"}}/></span>
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              <Nav style={{textAlign:"right"}}>
            <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">BE GREAT</Link>
            <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">AMBASSADOR</Link>
            <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">SCIENCE</Link>
            <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">CONTACT</Link>
            <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">ACCOUNT</Link>
            <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">SHOP</Link>
            {/* <NavDropdown
              title="More"
              id="offcanvasNavbarDropdown"
              className="listItem"
            >
              <NavDropdown.Item href="#action3">
                Cybertruck
              </NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Roadster
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">Semi</NavDropdown.Item>
              <NavDropdown.Item>News</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="English">
              <NavDropdown.Item>Portuguese-Br</NavDropdown.Item>
              <NavDropdown.Item>Portuguese-Pt</NavDropdown.Item>
              <NavDropdown.Item>Italian</NavDropdown.Item>
              <NavDropdown.Item>Spanish</NavDropdown.Item>
              <NavDropdown.Item>French</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>
    </div>
        <div className="Signintwo container">


<form onSubmit={handleSubmit}>
  <div class="mb-2">
    <label for="exampleInputEmail1" class="form-label" style={{color:"orange"}}><h3><b>Sign up</b></h3></label>
  </div>

  {err && showErrMsg(err)}
      {success && showSuccessMsg(success)}

  <div class="mb-0">
    <label for="exampleInputPassword1" class="form-label"><b>Join the (extra)ordinary club.</b></label>
  </div>

  <div class="mb-2">
    <label for="exampleInputPassword1" class="form-label">Get early access to new products, rewards, health tips, and more!</label>
  </div>

   <div class="mb-2">
    <label for="exampleInputPassword1" class="form-label"><b>Account Information</b></label>
  
 </div>

  
  <div class="row g-3 align-items-center">
  
   <div class="col-lg-5 col-sm-12">
   First Name
    <input type="text"  placeholder="Enter your first name"
            value={first_name}
            name="first_name"
            onChange={handleChangeInput}
             id="inputPassword6"
              class="form-control" 
              aria-describedby="passwordHelpInline"/>
  </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <div class="col-lg-5 col-sm-12">
  Last Name
    <input type="text" 
    placeholder="Enter your last name"
    id="inputPassword6"
    value={last_name}
    name="last_name"
    onChange={handleChangeInput} class="form-control" aria-describedby="passwordHelpInline"/>
  </div>
</div> 
<br/>
<div class="row g-3 align-items-center">
  
   <div class="col-lg-5 col-sm-12">
   Email
    <input type="email" 
    placeholder="Enter email address"
    id="inputPassword6"
    value={email}
    name="email"
    onChange={handleChangeInput} class="form-control" aria-describedby="passwordHelpInline"/>
  </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <div class="col-lg-5 col-sm-12">
  Create Password
    <input type="password" placeholder="Enter password"
            id="inputPassword6"
            value={password}
            name="password"
            onChange={handleChangeInput} class="form-control" aria-describedby="passwordHelpInline"/>
  </div>
</div> 
<br/>
<div class="row g-3 align-items-center">
  
   <div class="col-lg-5 col-sm-12">
   Confirm Password
    <input type="password"  placeholder="Confirm password"
            id="inputPassword6"
            value={cf_password}
            name="cf_password"
            onChange={handleChangeInput} class="form-control" aria-describedby="passwordHelpInline"/>
  </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <div class="col-lg-5 col-sm-12">
   Gender
    <input type="text" 
    placeholder="Enter your gender"
    id="inputPassword6"
    value={gender}
    name="gender"
    onChange={handleChangeInput} 
    class="form-control" aria-describedby="passwordHelpInline"/>
  </div>
</div> 
<br/>
<div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
    <label class="form-check-label" for="exampleCheck1">Yes! TectonTM may process my personal data (i.e. name and email address) to inform me about relevant<br/>
events, product updates, subscription notices, billing notices, and news. I can unsubscribe at any<br/>
time by clicking the unsubscribe in emails.</label>
<br/>   
<br/>
<p>By choosing "Continue", you agree to our <span style={{color:"orange",textDecoration:"underline"}}>Terms of Use</span> and <span style={{color:"orange",textDecoration:"underline"}}>Privacy Policy</span></p>
  </div>
  <button type="submit" class="btn px-5 py-2" style={{backgroundColor:"orange",color:"white"}}>CONTINUE</button>
  <div class="mt-3">
    <label for="exampleInputPassword1" class="form-label" >Already have an account? <span style={{color:"orange"}}><Link to="/login">Login.</Link></span></label>
  </div>
</form>
        </div>
        </>
    )
}

export default Signintwo;