import React from 'react';
import "../css/allproduct.css";
import { Link } from 'react-router-dom';
import LOGOORANGE from "../images/TECTON beta site.png";
import LOGOSIDE from "../images/TECTON beta site side.png";
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons';
import magma from '../images/MAGMA.png';
import { useEffect, useState } from 'react';
import QuickAdd from '../components/quikadd'

const Allproduct = () => {
    const [toggle, setToggle] = useState(false);

	const handleToggle = () => {
	  setToggle(pre => !pre)
	}


    
    return (

        <div class="all-product">
            <section class="section-products">
                <div class="container">
                    <div class=" cont-act-for col-sm-10">
                        <div class="row">
                            <div class="m-acc">
                                <h4 class="pro-duct" >All Products</h4>

                            </div>
                            <div class="sort-by" style={{alignItems:"right"}}>
                                
                                <button class="sort-of"> sort &nbsp;&nbsp;&nbsp; <i class="arrow down"></i></button>
                            </div>
                        </div>

                    </div>
                    
                    <div class="ketoo">
                            <h4><b>Ketone Hydration</b></h4>
                        </div>
                        <div class="keto-one-h">
                    <div class="row">
                       
                   
                        <div class="col-sm-2 col-lg-4 col-xl-3">
                            <div id="product-1" class="single-product">
                                <div class="part-1">
                                   
                                </div>
                                <div class="part-2">
                                    <h3 class="product-title"><b>Product</b></h3>
                                    <h3 class="product-title">details</h3>
                                    <h4 class="product-price">$00.00</h4><br/>
                                    <button onClick={handleToggle} class="product-btn-default" >QUICK ADD</button>
     {toggle && <QuickAdd close={() => setToggle(false)} />}

                                </div>
                               
                               
                            </div>
                        </div>
                        

                        <div class="col-sm-2 col-lg-4 col-xl-3">
                            <div id="product-2" class="single-product">
                                <div class="part-1">
                                    
                                </div>
                                <div class="part-2">
                                    <h3 class="product-title"><b>Product</b></h3>
                                    <h3 class="product-title">Ketone Hydration</h3>
                                    <h4 class="product-price">$00.00</h4><br/>
                                    <input class="product-btn-default" type="submit" value="QUICK ADD" ></input>
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-4 col-xl-3">
                            <div id="product-big" class="single-product">
                                <div class="part-1">
                                   
                                </div>
                              
                            
                            </div>
                        </div>
                     
                        <div class="ketoo">
                            <h4><b>Merchandise</b></h4>
                        </div>
                        <div class="row"></div>
                        <div class="col-sm-2 col-lg-4 col-xl-3">
                            <div id="product-1" class="single-product">
                                <div class="part-1">
                                   
                                </div>
                                <div class="part-2">
                                    <h3 class="product-title"><b>Polo shirt</b></h3>
                                    <h3 class="product-title">details</h3>
                                    <h4 class="product-price">$00.00</h4><br/>
                                    <input class="product-btn-default" type="submit" value="QUICK ADD" ></input>
                                </div>
                               
                            </div>
                        </div>
                      
                        <div class="col-sm-2 col-lg-4 col-xl-3">
                            <div id="product-2" class="single-product">
                                <div class="part-1">
                                    
                                </div>
                                <div class="part-2">
                                    <h3 class="product-title"><b>Gift card</b></h3>
                                    <h3 class="product-title">details</h3>
                                    <h4 class="product-price">$00.00</h4><br/>
                                    <input class="product-btn-default" type="submit" value="QUICK ADD" ></input>
                                </div>
                               
                            </div>
                        </div>
                        </div>  
                    </div>
                    </div>
                    <div>
                        <div class="image-story">
                    <div id="image-product">
                   
                        </div>
                        </div>
                    </div>
                    <div class="our-story-part">
                    <div class="keto">
                        <h4>Our Story</h4>
                    </div>
                    <div class="row justify-content-center text-center">
                    <div class="our-story">
                        <div id="story1">
                        <p>The Earth's mountains, valleys and oceans are shaped by its tectonic plates. Similarly,<br/> the mitrocondria in our cells can unleash
                        ourr ability to change our health and wellbeing.<br/> Perhaps even change the world.<br /> <br />
                        That's why we are named Tecton. Our produt helps the mitrocondria in our cell to<br/> metabolize energy in far more efficient way.<br/>
                        Lets make the most of our every day little moments. One of these moments might<br/> potentially change our lives. Or change our world </p>
                        </div>
                        <div id="story2">
                        <p>The Earth's mountains, valleys and oceans are shaped by its tectonic plates. Similarly, the mitrocondria in our cells can unleash
                        ourr ability to change our health and wellbeing. Perhaps even change the world.<br /> <br />
                        That's why we are named Tecton. Our produt helps the mitrocondria in our cell to metabolize energy in far more efficient way.<br/>
                        Lets make the most of our every day little moments. One of these moments might potentially change our lives. Or change our world </p>
                        </div>
                    </div>
                    </div>
                    <div className="btn-our" style={{ textAlign: "center" }}>
                        <input class="product-btn-story" type="submit" value="READ MORE" ></input>

                    </div>
                    <div class="help-diff">
                        <div class="row justify-content-center text-center">
                            <div class="col-md-8 col-lg-6">
                                <div class="header-allproduct">

                                    <h2><b>Help Us Make a Difference</b></h2>
                                </div>
                                </div>
                              
                                <div class="ourt-story">
                                <p>Are you an Ambassador to living your life to the fullest? Being healthy, beiing present, and <br/>showing up in the wolrd at your very best? <br />
                                    <br />
                                Then connect with founder of Tecton and learn more about how we can work <br/>together.</p>
                           
                                </div>
                        </div>
                        <div className="btn-our" style={{ textAlign: "center" }}>
                            <input class="product-btn-help" type="submit" value="BECOME AN AMBASSADOR" ></input>
                        </div>
                    </div>
                </div>
               
            </section>
           

        </div>
         
        
        



    )
}

export default Allproduct;
