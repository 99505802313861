import "../css/Reset.css";
import { Link,useParams,useNavigate  } from "react-router-dom";
import LOGOORANGE from "../images/TECTON beta site.png";
import LOGOSIDE from "../images/TECTON beta site side.png";
import React from "react";
import TECHW from "../images/TECHW.png";
import "../css/Login.css";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useRef} from "react";
import axios from "axios";
import countryList from "react-select-country-list";
import Select from "react-select";
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircle} from "@fortawesome/free-solid-svg-icons"; 
import BEGREAT  from '../images/BEGREAT.png';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import Logo from "../images/Tecton side by side white logo.png";
import {
  Navbar,
  Container,
  NavDropdown,
  Offcanvas,
  Nav,
} from "react-bootstrap";
import {
  showErrMsg,
  showSuccessMsg,
} from "./utils/notification/Notification";
import { isLength, isMatch } from "./utils/validation/Validation";


const initialState = {
  password: "",
  cf_password: "",
  err: "",
  success: "",
};
function Reset(){
  const [data, setData] = useState(initialState);
  const { token } = useParams();
  const chalochale=useNavigate();
  const { password, cf_password, err, success } = data;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value, err: "", success: "" });
  };
  
   
  const handleResetPass = async () => {
    if (isLength(password))
      return setData({
        ...data,
        err: "Password must be at least 6 characters.",
        success: "",
      });

    if (!isMatch(password, cf_password))
      return setData({ ...data, err: "Password did not match.", success: "" });

    try {
      const res = await axios.post(
        "http://localhost:5000/user/reset",
        { password },
        {
          headers: { Authorization: token },
        }
      );

      return setData({ ...data, err: "", success: res.data.msg });
    } catch (err) {
      err.response.data.msg &&
        setData({ ...data, err: err.response.data.msg, success: "" });
    }
  };

    return(
      <>
      <div className="header" style={{backgroundColor:"black"}}>
      <div className="header_logo">
      <Link to="/" onClick={()=>('.navbar-collapse').collapse('hide')}> <img src={Logo} alt="Logo da Marca"></img></Link>
      </div>
      <div className="header_center">
      <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopLeftRadius:"22px",borderBottomLeftRadius:"22px"}}>BE GREAT</p></Link>
      <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">AMBASSADOR</p></Link>
      <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">SCIENCE</p></Link>
      <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">CONTACT</p></Link>
      <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">ACCOUNT</p></Link>
      <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopRightRadius:"22px",borderBottomRightRadius:"22px"}}>SHOP</p></Link>
      </div>

      {/* <div className="header_right">
         <p className="ghostItem">Shop</p>
        <p className="ghostItem">Account</p> 
        <p className="ghostItem">MENU</p>
      </div> */}

      <div className="revealGhostItems">
        <Navbar className="cont" expand={false}>
          <Container fluid>
            <Navbar.Toggle href="#" className="menu">
            <span><FontAwesomeIcon icon={faBars} style={{color:"white",fontWeight:"bolder"}}/></span>
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              <Nav style={{textAlign:"right"}}>
            <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">BE GREAT</Link>
            <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">AMBASSADOR</Link>
            <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">SCIENCE</Link>
            <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">CONTACT</Link>
            <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">ACCOUNT</Link>
            <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">SHOP</Link>
            {/* <NavDropdown
              title="More"
              id="offcanvasNavbarDropdown"
              className="listItem"
            >
              <NavDropdown.Item href="#action3">
                Cybertruck
              </NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Roadster
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">Semi</NavDropdown.Item>
              <NavDropdown.Item>News</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="English">
              <NavDropdown.Item>Portuguese-Br</NavDropdown.Item>
              <NavDropdown.Item>Portuguese-Pt</NavDropdown.Item>
              <NavDropdown.Item>Italian</NavDropdown.Item>
              <NavDropdown.Item>Spanish</NavDropdown.Item>
              <NavDropdown.Item>French</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>
    </div>
      
        <div className="Reset container">
           
<form onSubmit={()=>{chalochale("/login")}}>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label" style={{color:"orange"}}><h3><b>Reset Your Password</b></h3></label>

  </div>
  {err && showErrMsg(err)}
        {success && showSuccessMsg(success)}
  <div class="mb-2">
    <label for="exampleInputPassword1" class="form-label"><b>Hey there! You can now reset your password</b></label>
  </div>

  <div class="mb-4  ">
    <label for="exampleInputPassword1" class="form-label">Enter your email below and we can send you a password reset email.    </label>
  </div>
  <div class="col-lg-5 col-sm-12">
  New Password
    <input type="password" id="inputPassword6"  
          name="password"
          value={password}
          onChange={handleChangeInput} class="form-control" aria-describedby="passwordHelpInline"/>
  </div>
  <br/>
  <div class="col-lg-5 col-sm-12">
  Reconfirm New Password
    <input type="password" id="inputPassword6"
          name="cf_password"
          value={cf_password}
          onChange={handleChangeInput} class="form-control" aria-describedby="passwordHelpInline"/>
  </div>
  <br/>
   
   <button type="submit" class="btn px-5 py-2" style={{backgroundColor:"orange",color:"white"}} onClick={handleResetPass} disabled={!password||!cf_password}>CONFIRM</button><br/><br/>
  {/* <label for="exampleInputPassword1" class="form-label" >Didn't receive the code? <span style={{color:"orange"}}>Resend email.</span></label> */}
</form>
        </div>
        </>
    )
}

export default Reset;