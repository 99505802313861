import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import cycle from '../images/cycle.jpeg';
import Road from '../images/Run cropped.jpg';
import Fish from '../images/Fish crop.jpg';
import golf from '../images/golf crop.jpg';
import piano from '../images/piano.jpg';
import old from '../images/Gymball crop.jpg';
import friend from '../images/Party crop.jpg';
import swim from '../images/Snow crop.jpg';
import kick from '../images/golf crop.jpg';
import GLACIER from '../images/GLACIER.png';
import MAGMA from '../images/MAGMA.png'
import Footer from './Footer';
import blueblack from '../images/glacier back.png';
import redback from '../images/magma back.png';
import meditation from '../images/meditation crop.jpg'
import plants from '../images/plants.png';
import uncle from '../images/uncle.jpg';
import { Link } from 'react-router-dom';
import redcan from '../images/MAGMAN.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
// import {  } from '@fortawesome/free-solid-svg-icons';  
import bluecan from '../images/GLACIERN.png';
import "../css/Landing.css"
import { useRef } from 'react';
import { useState } from 'react';
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlassMinus } from '@fortawesome/free-solid-svg-icons';



function Landing(){
   

   

    const renderCustomThumbs = () => {

        return [
          <picture>
             <div class="image-content-above-read">
                  
                    
                  <h4 style={{color:"orange"}}><b><span style={{letterSpacing:"5px"}}>BE GREAT.</span></b></h4>
                  <p ><span><b>Live life in<br className='remove'/> High Definition.</b></span> </p>
                
                  {/* <a style={{height:"auto",width:"94%",color:"black",backgroundColor:"orange",padding:"4px 73px",fontSize:"110%"}}><b>R E A D</b></a>  */}

                </div>
           <Link to="./be-great" class="select" style={{height:"auto",width:"94%",color:"black",backgroundColor:"orange",padding:"4px 0px",fontSize:"110%",textAlign:"center",textDecoration:"none"}}><b>R E A D</b></Link>
           <br className='removenot'/>
           <div className='dont-display-line'>
           <div className='white-line' style={{backgroundColor:"orange",display:"inline-block"}}></div>&nbsp;&nbsp; <div className='white-line'></div>&nbsp;&nbsp; <div className='white-line'></div>&nbsp;&nbsp; <div className='white-line'></div> &nbsp;&nbsp;<div className='white-line'></div>
           </div>
            {/* <br/> */}
            <div class='bgblack'>
              
               <span style={{display:"inline-block"}} ><b>Zero Carbs, Zero Caffeine, Zero Jitters.</b></span>
            </div>
    
               
            
          </picture>,
          <picture>
             <div class="image-content-above-read">
                  
             <h4 style={{color:"orange"}}><b><span style={{letterSpacing:"5px"}}>KETO DIETS.</span></b></h4>
                    <p ><span style={{color:"white"}}><b>Challenges and <br className='remove'/>Alternatives</b></span> </p>
                 
                  {/* <a style={{height:"auto",width:"94%",color:"black",backgroundColor:"orange",padding:"4px 73px",fontSize:"110%"}}><b>R E A D</b></a>  */}

                </div>
          <Link to="./keto-diets" style={{height:"auto",width:"94%",color:"black",backgroundColor:"orange",padding:"4px 0px",fontSize:"110%",textAlign:"center",textDecoration:"none"}}><b>L E A R N</b></Link>
           <br className='removenot'/>
           <div className='dont-display-line'>
           <div className='white-line' ></div>&nbsp;&nbsp; <div className='white-line' style={{backgroundColor:"orange",display:"inline-block"}}></div>&nbsp;&nbsp; <div className='white-line'></div>&nbsp;&nbsp; <div className='white-line'></div> &nbsp;&nbsp;<div className='white-line'></div>
           
           </div>
            <div class='bgblack'>
            
            <span style={{display:"inline-block"}}><b>Following a Keto Diet is Beneficial, but hard.</b></span> 

            </div>
             
          </picture>,
          <picture>
              <div class="image-content-above-read">
                  
              <h4 style={{color:"orange"}}><b><span style={{letterSpacing:"5px"}}>TRUE PURPOSE</span></b></h4>
                    <p ><span style={{color:"white"}}><b>Why we <br className='remove'/> started this</b></span> </p>
                   
                       {/* <a style={{height:"auto",width:"94%",color:"black",backgroundColor:"orange",padding:"4px 73px",fontSize:"110%"}}><b>R E A D</b></a>  */}
     
                     </div>
             <Link to='./true-purpose' style={{height:"auto",width:"94%",color:"black",backgroundColor:"orange",padding:"4px 0px",fontSize:"110%",textAlign:"center",textDecoration:"none"}}><b>R E A D</b></Link>
              <br className='removenot'/>
              <div className='dont-display-line'>
              <div className='white-line'></div>&nbsp;&nbsp; <div className='white-line'></div>&nbsp;&nbsp; <div className='white-line' style={{backgroundColor:"orange",display:"inline-block"}}></div>&nbsp;&nbsp; <div className='white-line'></div> &nbsp;&nbsp;<div className='white-line'></div>
           </div>
            <div class='bgblack'>
            <span style={{display:"inline-block"}}><b>Improve the health and wellbeing of all people.</b></span>
            </div>
            {/* <picture class="secondthumb">
                sdsd
              </picture> */}
          </picture>,
          <picture>
           <div class="image-content-above-read">
                  
           <h4 style={{color:"orange"}}><b><span style={{letterSpacing:"5px"}}>EXOGENOUS KETONES</span></b></h4>
                    <p ><span style={{color:"white"}}><b>A nature identical<br className='remove'/> alternative</b></span> </p>
                   
                           {/* <a style={{height:"auto",width:"94%",color:"black",backgroundColor:"orange",padding:"4px 73px",fontSize:"110%"}}><b>R E A D</b></a>  */}
         
                         </div>
           <Link to='./exogenous-ketones' style={{height:"auto",width:"94%",color:"black",backgroundColor:"orange",padding:"4px 0px",fontSize:"110%",textAlign:"center",textDecoration:"none"}}><b>L E A R N</b></Link>
            <br className='removenot'/>
           <div className='dont-display-line'>
           <div className='white-line'></div>&nbsp;&nbsp; <div className='white-line'></div>&nbsp;&nbsp; <div className='white-line'></div>&nbsp;&nbsp; <div className='white-line' style={{backgroundColor:"orange",display:"inline-block"}}></div> &nbsp;&nbsp;<div className='white-line'></div>
             </div>
          <div class='bgblack'>
          <span style={{display:"inline-block"}}><b>The science behind Tecton™.</b></span>
          </div>
          {/* <picture class="secondthumb">
                sdsd
              </picture> */}
        </picture>,
        <picture>
          
          <div class="image-content-above-read">
                  
          <h4 style={{color:"orange"}}><b><span style={{letterSpacing:"5px"}}>FASTING</span></b></h4>
                    <p ><span style={{color:"white"}}><b>It's been around<br className='remove'/> a long time</b></span> </p>
                
                           {/* <a style={{height:"auto",width:"94%",color:"black",backgroundColor:"orange",padding:"4px 73px",fontSize:"110%"}}><b>R E A D</b></a>  */}
         
                         </div>
         
         <Link to='./fasting' style={{height:"auto",width:"94%",color:"black",backgroundColor:"orange",padding:"4px 0px",fontSize:"110%",textAlign:"center",textDecoration:"none"}}><b>R E A D</b></Link>
          <br className='removenot'/>
          <div className='dont-display-line'>
          <div className='white-line'></div>&nbsp;&nbsp; <div className='white-line'></div>&nbsp;&nbsp; <div className='white-line'></div>&nbsp;&nbsp; <div className='white-line'></div> &nbsp;&nbsp;<div className='white-line' style={{backgroundColor:"orange",display:"inline-block"}}></div>
            </div>
        <div class='bgblack'>
        <span style={{display:"inline-block"}}><b>Ketosis through fasting can unleash the mind.</b></span>
        </div>
        {/* <picture class="secondthumb">
                sdsd
              </picture> */}
      </picture>,
        ]

       

      
        };
     const[state,setstate]=useState(redcan);
     const[state2,setstate2]=useState("GLACIER");
     const[state3,setstate3]=useState((<span style={{fontWeight:"bold"}}>MAGMA</span>));
     const[state4,setstate4]=useState("red");
     const[state5,setstate5]=useState("");
     const[state6,setstate6]=useState("");
     const[state7,setstate7]=useState("");
    const[state9,setstate9]=useState("hover-zoom");
    const[state10,setstate10]=useState("");
    const[state11,setstate11]=useState("CLICK IMAGE TO ZOOM IN");
        
        
    return(
        <div>
      <section class="section-one">
        
 <Carousel renderThumbs={renderCustomThumbs}  autoPlay={true} interval={4000}  showArrows={false} infiniteLoop={true} thumbWidth="19%"  showIndicators={false} showStatus={false} stopOnHover={false} >
                <div>
                  <div style={{display:"none"}}>
                    {/* <img src={Road} /> */}
                    </div>  
                    <div className='img-road'>

                    </div>
                  <div class="image-content">
                  
                    
                    <h4 style={{color:"orange"}}><b><span style={{letterSpacing:"5px"}}>BE GREAT.</span></b></h4>
                    <p ><span><b>Live life in<br className='remove'/> High Definition.</b></span> </p>
                  
                    {/* <a style={{height:"auto",width:"94%",color:"black",backgroundColor:"orange",padding:"4px 73px",fontSize:"110%"}}><b>R E A D</b></a>  */}

                  </div>
                </div>
                <div>
                <div className='img-fish'>

                 </div>
                    <div class="image-content">
                    <h4 style={{color:"orange"}}><b><span style={{letterSpacing:"5px"}}>KETO DIETS.</span></b></h4>
                    <p ><span style={{color:"white"}}><b>Challenges and Alternatives</b></span> </p>
                    {/* <a style={{height:"auto",width:"auto",color:"black",backgroundColor:"orange",padding:"4px 73px",fontSize:"110%",position:"relative",left:"224px",top:"-22px"}}><b>L E A R N</b></a>  */}

                  </div>
                </div>
                <div>
                <div className='img-uncle'>

</div>
                    <div class="image-content">
                    <h4 style={{color:"orange"}}><b><span style={{letterSpacing:"5px"}}>TRUE PURPOSE</span></b></h4>
                    <p ><span style={{color:"white"}}><b>Why we <br className='remove'/> started this</b></span> </p>
                    
                    {/* <a style={{height:"auto",width:"auto",color:"black",backgroundColor:"orange",padding:"4px 71px",fontSize:"110%",position:"relative",left:"466px",top:"-23px"}}><b>R E A D</b></a>  */}

                  </div>
                </div>
                <div>
                <div className='img-plants'>

</div>
                    <div class="image-content">
                    <h4 style={{color:"orange"}}><b><span style={{letterSpacing:"5px"}}>EXOGENOUS KETONES</span></b></h4>
                    <p ><span style={{color:"white"}}><b>A nature identical alternative</b></span> </p>
                 
                    {/* <a style={{height:"auto",width:"auto",color:"black",backgroundColor:"orange",padding:"4px 71px",fontSize:"110%",position:"relative",left:"692px",top:"-23px"}}><b>R E A D</b></a>  */}

                  </div>
                </div>
                <div>
                <div className='img-meditation'>

                </div>
                    <div class="image-content">
                    <h4 style={{color:"orange"}}><b><span style={{letterSpacing:"5px"}}>FASTING</span></b></h4>
                    <p ><span style={{color:"white"}}><b>It's been around<br className='remove'/> a long time</b></span> </p>
                    
                    {/* <a style={{height:"auto",width:"auto",color:"black",backgroundColor:"orange",padding:"4px 71px",fontSize:"110%",position:"relative",left:"933px",top:"-23px"}}><b>R E A D</b></a>  */}

                  </div>
                </div>
            </Carousel>
            </section>

         <section class="section-two">
        
  
   
  

         <div class="row">
  <div class="col-sm-6 section-four-one">
   <p class="card-title text-white">It’s life without the burden<br/>
and jitters of carbs and caffeine.</p><br/><br/>
   <p class="card-title text-white">  Unleash your <b>full potential</b> with Tecton™.<br/>
</p>
<br/>

<p class="card-title text-white" >  Push your boundaries.<br/>
</p>
<p class="card-title text-white">Redefine your limits.<br/>
</p>
<p class="card-title text-white"> Run a marathon.<br/>
</p>
<p class="card-title text-white">  Summit a peak.<br/>
</p>
<p class="card-title text-white">  Land the perfect pitch.<br/>
</p>
<p class="card-title text-white">  Be fully present in every moment.<br/>    
</p>
<br/>
<br/>
<div class="b-ton">
            <Link to="/preorder"><button class="land-two-button" >Pre-Order Now</button></Link>
            <Link to="/ambassador"><button class="land-three-button" >Become an Ambassador</button></Link>
            {/* <Link to="/preorder"><button class="space-two-button" >Pre-Order Now</button></Link>
            <Link to="/be-great"><button class="space-three-button" >Learn More</button></Link>  */}
            </div>
  </div>
  <div id="hide1" class="col-sm-6 section-four-two">
    <div className='img-wrapper'>
    <img class="img1" src={state} height="460px" className={state9} onClick={()=>{
      setstate9("hover-zoom-in")
    }}
    onMouseLeave={()=>{
      setstate9("hover-zoom")
    }}></img>
    </div>
    <br/>
    <br/>
    <div class="row">
      <div class="col-6" style={{textAlign:"right"}}><FontAwesomeIcon onClick={()=>{
        if(state===blueblack){
          setstate(bluecan);
          setstate6("blue");
          setstate7("")
        }    
        
        if(state===redcan){
        setstate(bluecan);
        setstate6("blue");
        setstate7("");
        setstate4("");
        setstate5("");
        setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>)
        }
         
        if(state===redback){
          setstate(bluecan);
          setstate6("blue");
          setstate7("");
          setstate4("");
          setstate5("");
          setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>);
          setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>)
          }

      }
      
      } icon={faCircle} style={{cursor:"pointer",color:`${state6}`}}/>&nbsp;&nbsp;&nbsp;
      <FontAwesomeIcon onClick={()=>{
      if(state===bluecan){
        setstate(blueblack);
        setstate7("blue");
        setstate6("")
      }

      if(state===redcan){
        setstate(blueblack);
        setstate6("");
        setstate7("blue");
        setstate4("");
        setstate5("");
        setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>)

      }

      if(state===redback){
        setstate(blueblack);
        setstate6("");
        setstate7("blue");
        setstate4("");
        setstate5("");
        setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>)

      }
      
      }
      
      } icon={faCircle} style={{cursor:"pointer",color:`${state7}`}}/>&nbsp;&nbsp;</div>

        

      <div class="col-6"><div class="col-6" style={{textAlign:"left"}} >&nbsp;&nbsp;<FontAwesomeIcon onClick={()=>{
      
      if(state===redback){
        setstate(redcan);
        setstate4("red");
        setstate5("");
      }   

      if(state===bluecan){
        setstate(redcan);
        setstate6("");
        setstate7("");
        setstate4("red");
        setstate5("");
        setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>)
        }

        if(state===blueblack){
          setstate(redcan);
          setstate6("");
          setstate7("");
          setstate4("red");
          setstate5("");
          setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>);
          setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>)
          }
      
      
      }
      
      } icon={faCircle} style={{cursor:"pointer",color:`${state4}`}}/>&nbsp;&nbsp;&nbsp;
      <FontAwesomeIcon onClick={()=>{
       if(state===redcan){
        setstate(redback);
        setstate5("red");
        setstate4("")
      }

      if(state===bluecan){
        setstate(redback);
        setstate6("");
        setstate7("");
        setstate4("");
        setstate5("red");
        setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>)
        }
      
        if(state===blueblack){
          setstate(redback);
          setstate6("");
          setstate7("");
          setstate4("");
          setstate5("red");
          setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>);
          setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>)
          }

      }
      
      } icon={faCircle} style={{cursor:"pointer",color:`${state5}`}}/></div></div>
      </div>
   <div style={{color:"white"}} >
   <span style={{cursor:"pointer"}} onClick={(()=>{return (setstate(bluecan),setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>),setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>),setstate4(""),setstate5(""),setstate6("blue"),setstate7(""))})}>{state2}</span> 
     <span style={{fontSize:"30px",padding:"10px"}}>|</span> 
     <span style={{cursor:"pointer"}} onClick={(()=>{return (setstate(redcan),setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>),setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>),setstate6(""),setstate7(""),setstate4("red"),setstate5(""))})}>{state3}</span> 
   </div>
  </div>
</div>
<div id="hide2" class="row">
  {/* <div className='abovecan'>
    <Link to="./preorder"><button>Pre-Order Now</button></Link>
  </div> */}
  <div class="col-sm-6 section-four-two">
    <div className='img-wrapper'>
    <img class="img1" src={state} height="460px" className={state10} 
      //   onClick={()=>{
      //   setstate10("hover-zoom-in-mobile")  ;
      //   setstate11("CLICK HERE TO ZOOM OUT") 
      // }}
      ></img>   
    </div>
    <br/>
    <br/>
    <div class="row">
      <div class="col-6" style={{textAlign:"right"}}><FontAwesomeIcon onClick={()=>{
        if(state===blueblack){
          setstate(bluecan);
          setstate6("blue");
          setstate7("")
        }    
        
        if(state===redcan){
        setstate(bluecan);
        setstate6("blue");
        setstate7("");
        setstate4("");
        setstate5("");
        setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>)
        }
         
        if(state===redback){
          setstate(bluecan);
          setstate6("blue");
          setstate7("");
          setstate4("");
          setstate5("");
          setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>);
          setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>)
          }

      }
      
      } icon={faCircle} style={{cursor:"pointer",color:`${state6}`}}/>&nbsp;&nbsp;
      <FontAwesomeIcon onClick={()=>{
      if(state===bluecan){
        setstate(blueblack);
        setstate7("blue");
        setstate6("")
      }

      if(state===redcan){
        setstate(blueblack);
        setstate6("");
        setstate7("blue");
        setstate4("");
        setstate5("");
        setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>)

      }

      if(state===redback){
        setstate(blueblack);
        setstate6("");
        setstate7("blue");
        setstate4("");
        setstate5("");
        setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>)

      }
      
      }
      
      } icon={faCircle} style={{cursor:"pointer",color:`${state7}`}}/>&nbsp;&nbsp;</div>

        

      <div class="col-6"><div class="col-6" style={{textAlign:"left"}} >&nbsp;&nbsp;<FontAwesomeIcon onClick={()=>{
      
      if(state===redback){
        setstate(redcan);
        setstate4("red");
        setstate5("");
      }   

      if(state===bluecan){
        setstate(redcan);
        setstate6("");
        setstate7("");
        setstate4("red");
        setstate5("");
        setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>)
        }

        if(state===blueblack){
          setstate(redcan);
          setstate6("");
          setstate7("");
          setstate4("red");
          setstate5("");
          setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>);
          setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>)
          }
      
      
      }
      
      } icon={faCircle} style={{cursor:"pointer",color:`${state4}`}}/>&nbsp;&nbsp;
      <FontAwesomeIcon onClick={()=>{
       if(state===redcan){
        setstate(redback);
        setstate5("red");
        setstate4("")
      }

      if(state===bluecan){
        setstate(redback);
        setstate6("");
        setstate7("");
        setstate4("");
        setstate5("red");
        setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>);
        setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>)
        }
      
        if(state===blueblack){
          setstate(redback);
          setstate6("");
          setstate7("");
          setstate4("");
          setstate5("red");
          setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>);
          setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>)
          }

      }
      
      } icon={faCircle} style={{cursor:"pointer",color:`${state5}`}}/></div></div>
      </div>
   <div style={{color:"white"}} >
   <span style={{cursor:"pointer"}} onClick={(()=>{return (setstate(bluecan),setstate2(<span style={{fontWeight:"bold"}}>GLACIER</span>),setstate3(<span style={{fontWeight:"normal"}}>MAGMA</span>),setstate4(""),setstate5(""),setstate6("blue"),setstate7(""))})}>{state2}</span> 
     <span style={{fontSize:"30px",padding:"10px"}}>|</span> 
     <span style={{cursor:"pointer"}} onClick={(()=>{return (setstate(redcan),setstate3(<span style={{fontWeight:"bold"}}>MAGMA</span>),setstate2(<span style={{fontWeight:"normal"}}>GLACIER</span>),setstate6(""),setstate7(""),setstate4("red"),setstate5(""))})}>{state3}</span> 
      {/* <div onClick={()=>{return setstate10("hover-zoom"),setstate11("CLICK IMAGE TO ZOOM IN")}}>{state11}</div> */}
    <div class="row"> 
      <div class="col-6" style={{textAlign:"right"}} onClick={()=>{return setstate10("hover-zoom-in-mobile")}}><FontAwesomeIcon icon={faMagnifyingGlassPlus} />&nbsp;&nbsp;</div>  
      <div class="col-6" style={{textAlign:"left"}} onClick={()=>{return setstate10("hover-zoom")}}>&nbsp;&nbsp;<FontAwesomeIcon icon={faMagnifyingGlassMinus}/></div>
      </div>
   </div>
  </div>
  {/* <div className='abovecan'>
    <Link to="./preorder"><button>Pre-Order Now</button></Link>
    <Link to="./preorder"><button>Become an Ambassador</button></Link>
  </div> */}
  <div class="col-sm-6 section-four-one" id="hide1">
   <p class="card-title text-white">It’s life without the burden<br/>
and jitters of carbs and caffeine.</p>
<div></div>
   <p class="card-title text-white">  Unleash your <b>full potential</b><br/> with T E C T O N.
   <div></div>
   <div></div>
</p>
<br/>

<p class="card-title text-white" >  Push your boundaries.<br/>

Redefine your limits.<br/>

 Run a marathon.<br/>
  Summit a peak.<br/>
  Land the perfect pitch.<br/>
  Be fully present in every moment. 
</p>
<br/>
<br/>
<Link to='./preorder'><button class="section-four-button">Pre-Order Now</button></Link>


  </div>
</div>














  
  
  

           
           </section>   

           <section class="section-three">
             <div>
             <div id="carouselExampleIndicatorsLeft" class="carousel slide carousel-slide carousel-thumbs-top"  data-bs-ride="carousel" data-bs-interval="5000" data-bs-pause="false">

        
<div id="sliding" class="slider carousel-indicators position-absolute"  >
  <div   data-bs-target="#carouselExampleIndicatorsLeft" data-bs-slide-to="0" class="active text-center tabs2" aria-current="true" aria-label="Slide 1" style={{width:"25%",textAlign:"center"}}>
<p>FOCUS</p>
{/* <div class="active_state">
</div> */}
</div>

  <div data-bs-target="#carouselExampleIndicatorsLeft"  class="tabs2"  data-bs-slide-to="1" aria-label="Slide 2"  style={{width:"25%",textAlign:"center"}}>
  <p>ENDURE</p>
  {/* <div class="active_state">

</div> */}
</div>


  <div data-bs-target="#carouselExampleIndicatorsLeft"  class="tabs2" data-bs-slide-to="2" aria-label="Slide 3"  style={{width:"25%",textAlign:"center"}}>
  <p>RECOVER</p>
  {/* <div class="active_state">

</div> */}
</div>

<div  data-bs-target="#carouselExampleIndicatorsLeft"    class="tabs2" data-bs-slide-to="3" aria-label="Slide 4"  style={{width:"25%",textAlign:"center"}}>
<p>PLAY</p>
  {/* <div class="active_state">

</div> */}
</div>

</div>


<div class="carousel-inner">
  <div class="carousel-item active" data-interval="1000" >
    <div className='img-golf-crop'>
    {/* <img src={kick} class="d-block w-100" alt="..." /> */}
    <div class="caption-1">
    <div class="carousel-caption">
      
       
      <p className='golf-text' style={{color:"white"}}>One serving of Tecton™<br className='remove'/> every<br className='removenot'/> four hours<br className='remove'/> keeps you <b>in the zone</b>. <br/><br/>Live each moment in high definition.</p>
     
      <a href="https://docs.google.com/document/d/1qLmc1cXQ2PFYtpPAnGLug86RcHgPzk0Y88rOaWbie6Y/edit?usp=sharing"><Link to='./preorder'><button class="text-white newbutton">Pre-Order Now</button></Link></a>
      <div className='mobile-golf-text' style={{textAlign:"left",fontSize:"26px"}}>
      One serving of Tecton™<br/>every four hours<br/> keeps you <b>in the zone</b>.<br/> <br/><br/>Live each moment<br/> in high definition.
      </div>
      
    </div>
    </div>
    </div>
  </div>
  <div class="carousel-item"  >
  <div className='img-snowboard'>
    {/* <img src={swim} class="d-block w-100"  /> */}
    <div class="caption-2">
    <div class="carousel-caption">
      
      <a href="https://docs.google.com/document/d/1qLmc1cXQ2PFYtpPAnGLug86RcHgPzk0Y88rOaWbie6Y/edit?usp=sharing"><Link to='./preorder'><button class="text-white newbutton" >Pre-Order Now</button></Link></a>
      
      <br/>
      
      <br/>
      <p style={{color:"black"}}>Drink 30 minutes before your run or race<br/> and discover your extra-ordinary potential.</p>
      
      </div>
      </div>
    </div>
  </div>
  <div class="carousel-item" >
  <div className='img-old'>
    {/* <img src={old} class="d-block w-100" alt="..." /> */}
    <div class="caption-2">
    <div class="carousel-caption">
     
      <a href="https://docs.google.com/document/d/1qLmc1cXQ2PFYtpPAnGLug86RcHgPzk0Y88rOaWbie6Y/edit?usp=sharing"><Link to='./preorder'><button class="text-white newbutton" >Pre-Order Now</button></Link></a>
      <br/>
      
      <br/>
      <p>Have one drink before your workout and hit the gym, <br/>the trail, or the links every day of the week. Who needs a rest day with Tecton™? </p>
      
      </div>
     </div>
    </div>
  </div>
  <div class="carousel-item" >
  <div className='img-party'> 
      {/* <img src={friend} class="d-block w-100" alt="..." /> */}
      <div class="caption-2">
      <div class="carousel-caption">
      
      
      <a href="https://docs.google.com/document/d/1qLmc1cXQ2PFYtpPAnGLug86RcHgPzk0Y88rOaWbie6Y/edit?usp=sharing"><Link to='./preorder'><button class="text-white newbutton">Pre-Order Now</button></Link></a>
      <br/>
      
      <br/>
      <p>If you work hard and play hard, put away those sugary mixers <br/> and enjoy the moment. Without regrets the next morning.</p>
      
      </div>
     </div>
      
    </div>
  </div>
</div>


</div>
             </div>

           </section>
           <section>
             
           
           </section>
           {/* <Footer/> */}
           <div className='img-fitter'>
          
           </div>
        </div>
    )
}

export default Landing;