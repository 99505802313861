import React from 'react';
import "../css/billing.css";
import { Link } from 'react-router-dom';
import LOGOORANGE from "../images/TECTON beta site.png";
import LOGOSIDE from "../images/TECTON beta site side.png";


const Billing = () => {
  return (
    <div class="acc">
      <div class="Act">
        <div id="dep2">
          <div class="row">
            <div class=" navl col-sm-1">
              <div class="contact-info">
                <div class="outl">
                  <nav
                    id="nav-menu"
                    class="navbar navbar-expand-lg navbar-light fixed-top bgset "
                    style={{ backgroundColor: "black" }}
                  >
                    <div class="container-fluid">
                      <a class="navbar-brand" href="#">
                        <Link to="/" onClick={() => ('.navbar-collapse').collapse('hide')}><div onClick={() => (window).scrollTo(0, 0)} class="hide-nav-pc"> <img class="first-image" src={LOGOORANGE} ></img></div>
                          <div onClick={() => (window).scrollTo(0, 0)} class="hide-nav-mobile"> <img class="first-image1" src={LOGOSIDE} ></img></div>
                          {/* <div onClick={()=>(window).scrollTo(0,0)}><img class="second-image" src={TECHW}></img></div> */}
                        </Link>
                      </a>
                      <button
                        class="navbar-toggler navbar-left"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNavAltMarkup"
                        aria-controls="navbarNavAltMarkup"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span class="navbar-toggler-icon"></span>
                      </button>
                      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div class="navbar-nav ms-auto bgcolor">
                          <div class="navbar-nav">
                            <Link to="/true-purpose"
                              onClick={() => ".navbar-collapse".collapse("hide")}
                              class="nav-link"
                              data-toggle="collapse"
                              data-bs-target=".navbar-collapse"
                            >
                              <b style={{ paddingLeft: "20px" }}>BE GREAT</b>
                            </Link>
                            <Link
                              to="/ambassador1"
                              onClick={() => ".navbar-collapse".collapse("hide")}
                              class="nav-link"
                              data-toggle="collapse"
                              data-bs-target=".navbar-collapse"
                            >
                              <b class="amb-b">AMBASSADOR</b>
                            </Link>

                            <Link
                              onClick={() => ".navbar-collapse".collapse("hide")}
                              to="/science"
                              class="nav-link"
                              href="#"
                              data-toggle="collapse"
                              data-bs-target=".navbar-collapse"
                            >
                              <b>SCIENCE</b>
                            </Link>
                            <Link
                              to="/preorder"
                              onClick={() => ".navbar-collapse".collapse("hide")}
                              class="nav-link"
                              data-toggle="collapse"
                              data-bs-target=".navbar-collapse"
                            >
                              <b>CONTACT</b>
                            </Link>
                            <Link
                              to="/account"
                              onClick={() => ".navbar-collapse".collapse("hide")}
                              class="nav-link"
                              data-toggle="collapse"
                              data-bs-target=".navbar-collapse"
                            >
                              <b class="amb-b">ACCOUNT</b>
                            </Link>
                            <Link
                              to="/preorder"
                              onClick={() => ".navbar-collapse".collapse("hide")}
                              class="nav-link"
                              data-toggle="collapse"
                              data-bs-target=".navbar-collapse"
                            >
                              <b>SHOP</b>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 ">
            <div class="contact-info">
              <div id="dep1">
                <ul class="menuo">
                  <li><a href="/order">ORDERS</a></li>
                  <li><a href="/subscription">SUBSCRIPTIONS</a></li>
                  <li><a href="/billing">BILLING</a></li>
                  <li><a href="/reward">REWARDS</a></li>
                  <li><a href="/refer">REFER A FRIEND</a></li>
                  <li><a href="/refer">ACCOUNT SETTINGS</a></li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div class="col-sm-8 ">
            <div class="my-acc">
              <h5 class="bil" >Billing</h5>
              <div ><p class="t-info">Payment Information</p></div>
            </div>
              <div class="detail-ship-acc">
              <div class="col">
                <input type="text" class="form-control-ship-acc " placeholder="Credit Card Number" id="fname" name="fname" />
              </div>
              <br />
                <div class="col-cvv">
                <input type="text" class="form-control-ship-exp-acc " placeholder="Exp.(MM/YYYY)" id="fname" name="fname" />
                <input type="text" class="form-control-ship-exp-acc" placeholder="CVV" id="fname" name="fname" />
              </div>
              <br />

              <div class="col">
                <input type="text" class="form-control-ship-acc " placeholder="Phone Number" id="fname" name="fname" />
              </div>
              <br />
              <div class="fade-cl"> For shipping Updates</div>
              <div class="review-leave-ship-pay-acc">
            Billing Address
							</div>
          <input type="checkbox" />
                            Same as Shipping Address
						</div>
            <div class="fo-group">
          <input class=" btn-btn" type="submit" value="SAVE" ></input>
        </div>
            </div>
         
          </div>
         

        
      </div>
    </div>

  )
}

export default Billing;
