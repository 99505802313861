import React from "react";
import LOGOORANGE from "../images/Tecton side by side white logo.png";
import LOGOSIDE from "../images/Tecton side by side white logo.png"
import TECHW from "../images/TECHW.png";
import "../css/Login.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useRef} from "react";
import axios from "axios";
import countryList from "react-select-country-list";
import Select from "react-select";
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircle,faCartShopping} from "@fortawesome/free-solid-svg-icons"; 
import BEGREAT  from '../images/BEGREAT.png';
import {faBars} from "@fortawesome/free-solid-svg-icons";
// import { faFacebookF } from '@fortawesome/free-brands-svg-icons'   
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import Logo from "../images/Tecton side by side white logo.png";
import {
  Navbar,
  Container,
  NavDropdown,
  Offcanvas,
  Nav,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  showErrMsg,
  showSuccessMsg,
} from "./utils/notification/Notification";
import { dispatchLogin } from "../redux/actions/authAction";
import { useDispatch } from "react-redux";
// import { GoogleLogin } from "react-google-login";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { gapi } from "gapi-script";

import Google from "../images/search.png";
import facebook from "../images/facebook.png";
import twitter from "../images/twitter.png"
import instagaram from "../images/instagram.png"
// import {fa} from 'react-icons/lib/ti/social-facebook-circular'

const initialState = {
  email: "",
  password: "",
  err: "",
  success: "",
};



function Login(){
    const myref=useRef();
    const[pas,setpas]=useState();
    const [user, setUser] = useState(initialState);
    const dispatch = useDispatch();
    const history = useNavigate();
    const [gmailData, setgmaildata] = useState();
    const [gmailData1, setgmaildata1] = useState();
    const { email, password, err, success } = user;

    const handleChangeInput = (e) => {
      const { name, value } = e.target;
      setUser({ ...user, [name]: value, err: "", success: "" });
    };
//    const[mal,setmal]=useState();
   const handleSubmit=async (e)=>{
    e.preventDefault();
    try {
      const res = await axios.post("http://localhost:5000/user/login", { email, password });
      setUser({ ...user, err: "", success: res.data.msg });

      localStorage.setItem("firstLogin", true);

      dispatch(dispatchLogin());
      history("/");
    } catch (err) {
      err.response.data.msg &&
        setUser({ ...user, err: err.response.data.msg, success: "" });
    }
   }

   const responseGoogle =async (response) => {
    if (response.tokenId) {
      try {
        const responseGoogle =await axios.post("http://localhost:5000/user/google_login", {
          tokenId: response.tokenId,
        });

        setUser({ ...user, error: "", success: responseGoogle?.data?.msg });
        localStorage.setItem("firstLogin", true);

        dispatch(dispatchLogin());
        history("/");
      } catch (err) {
        err?.response?.data?.msg &&
          setUser({ ...user, err: err.response.data.msg, success: "" });
      }
    } else {
      console.log("no token provided");
    }
  };

  // const responseGoogle = (res) => {
  //   console.log(res);
  // };

  const responseFacebook = async (response) => {
    try {
      const { accessToken, userID } = response;
      const res = await axios.post("http://localhost:5000/user/facebook_login", {
        accessToken,
        userID,
      });

      setUser({ ...user, error: "", success: res.data.msg });
      localStorage.setItem("firstLogin", true);

      dispatch(dispatchLogin());
      history("/");
    } catch (err) {
      err.response.data.msg &&
        setUser({ ...user, err: err.response.data.msg, success: "" });
    }
  };

  // const google = () => {
  //   window.open("http://localhost:5000/auth/google", "_self");
  // };

  useEffect(() => {
    function start() {
      gapi.auth2.init({
        client_id:
          "907979361300-ghf0md65u34jsjpvr47v0in45oklc2og.apps.googleusercontent.com",
      });
    }
    gapi.load("client:auth2", start);
  });


    return(
      <>
     <div className="header" style={{backgroundColor:`black`}}>
   <div className="header_logo">
        <Link to="/" onClick={()=>('.navbar-collapse').collapse('hide')}> <img src={Logo} alt="Logo da Marca"></img></Link>
        </div>
        <div className="header_center">
        <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopLeftRadius:"22px",borderBottomLeftRadius:"22px"}}>BE GREAT</p></Link>
        <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">AMBASSADOR</p></Link>
        <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">SCIENCE</p></Link>
        <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">CONTACT</p></Link>
        <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">ACCOUNT</p></Link>
        <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <NavDropdown title="SHOP" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">SHOP ALL</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">TECTON</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">MERCHANDISE</NavDropdown.Item>
            {/* <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
          </NavDropdown></Link>
              <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopRightRadius:"22px",borderBottomRightRadius:"22px"}}><FontAwesomeIcon icon={faCartShopping} style={{color:"white",fontWeight:"bolder"}}/></p></Link>
        </div>
  
  
  {/* <div className="header_right">
     <p className="ghostItem">Shop</p>
    <p className="ghostItem">Account</p> 
    <p className="ghostItem">MENU</p>
  </div> */}
  
  <div className="revealGhostItems">
    <Navbar className="cont" expand={false}>
      <Container fluid>
      <Link to="/preorder"><span><FontAwesomeIcon icon={faCartShopping} style={{color:"white",fontWeight:"bolder",fontSize:"30px"}}/></span></Link>
        <Navbar.Toggle href="#" className="menu">
        <span><FontAwesomeIcon icon={faBars} style={{color:"white",fontWeight:"bolder"}}/></span>
        </Navbar.Toggle>
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav style={{textAlign:"right"}}>
              <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">BE GREAT</Link>
              <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">AMBASSADOR</Link>
              <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">SCIENCE</Link>
              <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">CONTACT</Link>
              <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">ACCOUNT</Link>
              <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><NavDropdown title="SHOP">
                <NavDropdown.Item>SHOP ALL</NavDropdown.Item>
                <NavDropdown.Item>TECTON</NavDropdown.Item>
                <NavDropdown.Item>MERCHANDISE</NavDropdown.Item>
              </NavDropdown></Link>
              {/* <NavDropdown
                title="More"
                id="offcanvasNavbarDropdown"
                className="listItem"
              >
                <NavDropdown.Item href="#action3">
                  Cybertruck
                </NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Roadster
                </NavDropdown.Item>
                <NavDropdown.Item href="#action5">Semi</NavDropdown.Item>
                <NavDropdown.Item>News</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="English">
                <NavDropdown.Item>Portuguese-Br</NavDropdown.Item>
                <NavDropdown.Item>Portuguese-Pt</NavDropdown.Item>
                <NavDropdown.Item>Italian</NavDropdown.Item>
                <NavDropdown.Item>Spanish</NavDropdown.Item>
                <NavDropdown.Item>French</NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  </div>
  </div>
        <div className="Login container">
            
<form onSubmit={handleSubmit}>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label" style={{color:"orange"}}><h3><b>Login</b></h3></label>
  </div>

  {err && showErrMsg(err)}
      {success && showSuccessMsg(success)}
  <div class="mb-2">
    <label for="exampleInputPassword1" class="form-label"><b>Welcome back!</b></label>
  </div>

  <div class="mb-4  ">
    <label for="exampleInputPassword1" class="form-label">We value you as a TectonTM customer. Thank you for being (extra)ordinary.</label>

  </div>
  <div class="col-lg-5 col-sm-12">
   Email
    <input type="email"
            placeholder="Enter email address"
            id="inputpassword6"
            value={email}
            name="email"
            onChange={handleChangeInput}class="form-control" aria-describedby="passwordHelpInline" ref={myref}/>
  </div>
  <br/>
  <div class="col-lg-5 col-sm-12">
    Password
     <input type="password"
            placeholder="Enter password"
            id="inputpassword6"
            value={password}
            name="password"
            onChange={handleChangeInput} class="form-control" aria-describedby="passwordHelpInline"/>
   </div>
  <br/>
   
  <button type="submit" class="btn px-5 py-2" style={{backgroundColor:"orange",color:"white"}}>ENTER</button>
  <div class="mt-3">
  <Link to="/fyr" style={{textDecoration:"none"}}><span style={{color:"orange"}}>Forgot your password?</span></Link><br/><br/>
    <label for="exampleInputPassword1" class="form-label" >Don't have an account yet? <span style={{color:"orange"}}>Sign up.</span></label><br/><br/>
    <label for="exampleInputPassword1" class="form-label" >_____ or continue with _____</label><br/><br/>
    <div class="smedia">
    {/* <img src={Google} width="40px"  height="40px"></img>
    <img src={facebook} width="40px"  height="40px"></img>
    <img src={twitter} width="40px"  height="40px"></img>
    <img src={instagaram} width="40px" height="40px"></img> */}
    <GoogleLogin
    render={renderProps => (
      <button onClick={renderProps.onClick} disabled={renderProps.disabled} style={{border:"none",background:"none"}}><img src={Google} width="40px"  height="40px"></img></button>
    )}
          clientId="907979361300-ghf0md65u34jsjpvr47v0in45oklc2og.apps.googleusercontent.com"
          // buttonText="Login with google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
        />
        <div className="Loginoo"><img src={facebook} width="40px"  height="40px"></img>
        </div>
        <div className="loginsm">
        <FacebookLogin
          appId="530340118542002"
          autoLoad={false}
          fields="name,email,picture"
          callback={responseFacebook}
          cssClass="btnFacebook"
          className="fbpicture"
    // icon={<FontAwesomeIcon icon={faFacebook}/>}
        />
        </div>
    </div>
  </div>
</form>
        </div>
        </>
    )
}

export default Login;