import React from "react";
import LOGOORANGE from "../images/Tecton side by side white logo.png";
import LOGOSIDE from "../images/Tecton side by side white logo.png"
import TECHW from "../images/TECHW.png";
import "../css/career.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import axios from "axios";
import countryList from "react-select-country-list";
import Select from "react-select";
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircle} from "@fortawesome/free-solid-svg-icons"; 
import BEGREAT  from '../images/BEGREAT.png';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import Logo from "../images/Tecton side by side white logo.png";
import {
  Navbar,
  Container,
  NavDropdown,
  Offcanvas,
  Nav,
} from "react-bootstrap";




function Career() {
    return (
      
        <div>
          <div className="header" style={{backgroundColor:"black"}}>
<div className="header_logo">
<Link to="/" onClick={()=>('.navbar-collapse').collapse('hide')}> <img src={Logo} alt="Logo da Marca"></img></Link>
</div>
<div className="header_center">
<Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopLeftRadius:"22px",borderBottomLeftRadius:"22px"}}>BE GREAT</p></Link>
<Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">AMBASSADOR</p></Link>
<Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">SCIENCE</p></Link>
<Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><p className="ghostItem">CONTACT</p></Link>
<Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem">ACCOUNT</p></Link>
<Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}> <p className="ghostItem" style={{borderTopRightRadius:"22px",borderBottomRightRadius:"22px"}}><NavDropdown title="SHOP">
        <NavDropdown.Item>SHOP ALL</NavDropdown.Item>
        <NavDropdown.Item>TECTON</NavDropdown.Item>
        <NavDropdown.Item>MERCHANDISE</NavDropdown.Item>
      </NavDropdown></p></Link>
</div>

{/* <div className="header_right">
   <p className="ghostItem">Shop</p>
  <p className="ghostItem">Account</p> 
  <p className="ghostItem">MENU</p>
</div> */}

<div className="revealGhostItems">
  <Navbar className="cont" expand={false}>
    <Container fluid>
      <Navbar.Toggle href="#" className="menu">
      <span><FontAwesomeIcon icon={faBars} style={{color:"white",fontWeight:"bolder"}}/></span>
      </Navbar.Toggle>
      <Navbar.Offcanvas
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Nav style={{textAlign:"right"}}>
      <Link to="/true-purpose" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">BE GREAT</Link>
      <Link to="/ambassador" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">AMBASSADOR</Link>
      <Link to="/science" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">SCIENCE</Link>
      <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">CONTACT</Link>
      <Link to="/signinone" onClick={()=>('.navbar-collapse').collapse('hide')} className="listItem">ACCOUNT</Link>
      <Link to="/preorder" onClick={()=>('.navbar-collapse').collapse('hide')} style={{textDecoration:"none"}}><NavDropdown title="SHOP">
        <NavDropdown.Item>SHOP ALL</NavDropdown.Item>
        <NavDropdown.Item>TECTON</NavDropdown.Item>
        <NavDropdown.Item>MERCHANDISE</NavDropdown.Item>
      </NavDropdown></Link>
      {/* <NavDropdown
        title="More"
        id="offcanvasNavbarDropdown"
        className="listItem"
      >
        <NavDropdown.Item href="#action3">
          Cybertruck
        </NavDropdown.Item>
        <NavDropdown.Item href="#action4">
          Roadster
        </NavDropdown.Item>
        <NavDropdown.Item href="#action5">Semi</NavDropdown.Item>
        <NavDropdown.Item>News</NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title="English">
        <NavDropdown.Item>Portuguese-Br</NavDropdown.Item>
        <NavDropdown.Item>Portuguese-Pt</NavDropdown.Item>
        <NavDropdown.Item>Italian</NavDropdown.Item>
        <NavDropdown.Item>Spanish</NavDropdown.Item>
        <NavDropdown.Item>French</NavDropdown.Item>
      </NavDropdown> */}
    </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Container>
  </Navbar>
</div>
</div>
        
            <div class="outT">
                <div class="board1">
               
                      <div class="conten">
                      <div class="pret-text">
                      <div class="well">
                  <h2 class="order-tex">Careers</h2>
                  </div>
                   
                </div>
                        <div class="first-part" style={{ backgroundColor: "orange" }}>
                     <div class="opt" >
                        <b>CALLING ALL CHANGEMAKERS!</b> <br/>
                        <br/>
Apply now to become a part of Team (Extra) Ordinary. Come and help us to <b>BE GREAT!</b>
<br/>
<br/>
</div>
                            <b> Who are we?</b> <br/>
We are building a movement powered by (extra)ordinary people - a company designed for the 21st Century! <br/>
<br/>
<b style={{fontSize:"23px",fontWeight:"700"}}>Tecton™</b> is the world’s first “focused performance” brand with an immense innovation pipeline including products
designed to help with recovery, focus, weight loss, neuroprotection, cognitive focus and more. <br/>
<br/>
The company is co-founded by a Special Forces Combat Medic who has also worked for the Defense Advanced
Research Projects Agency of the Pentagon (DARPA) and the son of a Fighter Pilot who led global brands for
Coca-Cola.<br />
                            <br />
                            <b> Our Purpose</b> <br/>
                            <b style={{fontSize:"20px",fontWeight:"700"}}> TECTON™</b> is a purpose driven company with a mission to help people discover the extraordinary in themselves and in
others. <br/>
<br/>
We believe that we need to take care of veterans, especially those who suffer from the consequences of having been in
war. One percent of our revenues go to nonprofits that help disabled veterans and their families get back on their feet.<br />
                            <br />
                            <b> Who are we looking for?</b> <br/>
                            We are currently recruiting writers, designers, sales people, digital marketers, social media mavens, brand managers
and more! <br/>
<br/>
We need people who want to unleash their full potential and put a dent in the universe. <br/>
<br/>
Are you someone who wants to show up in the world at your very best? Then connect with the founders of Tecton and
learn more about how we can work together.!<br />
                        </div>
                            <br />
                          <div class="second-part">
                            
                            <div class="san" > <p  > Contact Information</p></div>
                                  <div class="in-de">
                                    <form onSubmit={(e)=>{
                                      e.preventDefault();
                                    }}>
                                     First Name: <input type="text" class="text-line1"  style={{}} /><br/>
                                     Last Name: <input type="text" class="text-line2" style={{}} /> <br/>  
                                     Email: <input type="email" class="text-line3"style={{}} />  <br/> 
                                      Where did you hear about Tecton™? <input type="text" class="text-line4" style={{}}/> <br/>
                                      What do you do? (50 words): <input type="text" class="text-linet"style={{}} />  <br/>   
                                        <br/>
                                        <br/>
                                        <div class="san" ><p>Relevant Links: (Bio, Portfolio etc.)</p></div>
                                     
                                            Street Address: <input type="text" class="text-line9" /> <br/>  
                                             City: <input type="text" class="text-line10" />  <br/> 
                                             {/* <span style={{ color: "red" }}>*</span> */}
                                             State:
                    <DatalistInput
    placeholder="select your state"
    // onSelect={(item) => set_state(item.value1)}
    items={[
      { value1: 'AK', value: 'Alaska'},
      { value1: 'TX', value: 'Texas'},
      { value1: 'AL', value: 'Alabama'},
      { value1: 'AR', value: 'Arkansas'},
      { value1: 'AZ', value: 'Arizona'},
      { value1: 'CA', value: 'California'},
      { value1: 'CO', value: 'Colorado'},
      { value1: 'CT', value: 'Connecticut'},
      { value1: 'DC', value: 'DistrictofColumbia'},
      { value1: 'DE', value: 'Delaware'},
      { value1: 'FL', value: 'Florida'},
      { value1: 'GA', value: 'Georgia'},
      { value1: 'HI', value: 'Hawaii'},
      { value1: 'IA', value: 'Iowa'},
      { value1: 'ID', value: 'Idaho'},
      { value1: 'IL', value: 'Illinois'},
      { value1: 'IN', value: 'Indiana'},
      { value1: 'KS', value: 'Kansas'},
      { value1: 'KY', value: 'Kentucky'},
      { value1: 'LA', value: 'Louisiana'},
      { value1: 'MA', value: 'Massachusetts'},
      { value1: 'MD', value: 'Maryland'},
      { value1: 'ME', value: 'Maine'},
      { value1: 'MI', value: 'Michigan'},
      { value1: 'MN', value: 'Minnesota'},
      { value1: 'MO', value: 'Missouri'},
      { value1: 'MS', value: 'Mississippi'},
      { value1: 'MT', value: 'Montana'},
      { value1: 'NC', value: 'NorthCarolina'},
      { value1: 'ND', value: 'NorthDakota'},
      { value1: 'NE', value: 'Nebraska'},
      { value1: 'NH', value: 'NewHampshire'},
      { value1: 'NJ', value: 'NewJersey'},
      { value1: 'NM', value: 'NewMexico'},
      { value1: 'NV', value: 'Nevada'},
      { value1: 'NY', value: 'NewYork'},
      { value1: 'OH', value: 'Ohio'},
      { value1: 'OK', value: 'Oklahoma'},
      { value1: 'OR', value: 'Oregon'},
      { value1: 'PA', value: 'Pennsylvania'},
      { value1: 'RI', value: 'RhodeIsland'},
      { value1: 'SC', value: 'SouthCarolina'},
      { value1: 'SD', value: 'SouthDakota'},
      { value1: 'TN', value: 'Tennessee'},
      { value1: 'TX', value: 'Texas'},
      { value1: 'UT', value: 'Utah'},
      { value1: 'VA', value: 'Virginia'},
      { value1: 'VT', value: 'Vermont'},
      { value1: 'WA', value: 'Washington'},
      { value1: 'WI', value: 'Wisconsin'},
      { value1: 'WV', value: 'WestVirginia'},
      { value1: 'WY', value: 'Wyoming'}
      ]}
  /><br/>
                                             Postal/Zip Code: <input type="number" class="text-lin12" />  <br/> <br/>
                                             Country: <br/> 
                                             <DatalistInput
    placeholder="Select your country"
    // onSelect={(item) => set_country(item.value1)}
    items={[
      { value1: 'U.S.' ,value: 'United States' },
  
    ]}/>
                                             <div class="Dgreat">
                                              <br/>
                                              <div class="bk">
                                              <img class="ft-image" src={BEGREAT} ></img><br/>
                                                <input class="down-button-bett" type="submit" value="Submit" ></input>
                                                 </div>
                   </div>
                                             </form>
                                             </div>
                                             
                        </div>
                      </div>
                     
                 
                  
                         
                </div>
            </div>

        </div>
    )
}

export default Career;
